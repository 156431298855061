.file-pickup {
  &_file-details {
    p:first-child {
      font-size: 1.5rem;
      margin-bottom: .2rem;
    }
    &.error-msg {
      p {
        font-size: 1.2rem;
      }
    }
  }
  .ovis-panel {
    &-item {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: $xs-max) {
  .ovis-panel {
    &-item {
      align-items: flex-start;
      flex-direction: column;
      gap: 1.4rem;
    }
  }
}