@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.content-side-nav {
  padding-top: 3.7rem;
  max-height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
}

.page {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  padding-left: 0;
  min-height: calc(100vh - $headerHeight - 0.4rem);
  padding-right: $spacingXLarge;
  padding-bottom: $spacingXXLarge;
  &_body {
    z-index: 1;
    padding-top: $spacingDefault;
  }
  &.--center-content {
    .page-content {
      margin: 0 auto;
    }
  }
  &.--small {
    .page-content,
    .page_sticky-header {
      max-width: 600px;
    }
  }
  &.--medium {
    .page-content,
    .page_sticky-header {
      max-width: 720px;
    }
  }
  &.--large {
    .page-content,
    .page_sticky-header {
      max-width: 1220px;
    }
  }
  &.--full {
    .page-content,
    .page_sticky-header {
      max-width: 1980px;
    }
  }
  &_documents-toggle {
    display: flex;
    margin-bottom: $spacingLarge;
    button {
      margin-right: $spacingMedium;
      background: transparent !important;
      &::after {
        transition: padding $transTimingLong ease-in-out;
        @include absolutePseudoElement('', initial, 0, 50%, initial);
        @include translate(-50%, 0);

        background: $textDark;
        height: 1px;
        padding: 0;
      }
      &.--active {
        color: $textDark;
        &::after {
          padding: 0 50%;
        }
      }
    }
  }
  &_sticky-header {
    position: sticky;
    top: 0;
    width: 100%;
    background: $appBackgroundGray;
    z-index: 10;
    padding: $spacingLarge 0 $spacingXLarge;
  }
  &.--has-page-nav {
    .page-content {
      padding-left: 220px;
    }
  }
  &.--side-nav-open {
    @media screen and (max-width: $xs-max) {
      margin-left: 0;
    }
  }
  .page-content {
    width: 100%;
    padding-left: $spacingLarge;
    @include tablet {
      display: flex;
      flex-direction: column;
      padding-left: $spacingMedium;
    }
  }
  @include tablet {
    padding-right: $spacingSmall;
  }
}
