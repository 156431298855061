.search-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  transition: height 0.3s ease-in-out;
  &.--transition-to-visible {
    height: 50px;
    overflow: hidden;
  }
  &.--transition-to-hidden {
    height: 0px;
    overflow: hidden;
  }
  &.--hidden {
    overflow: hidden;
    height: 0px;
  }
  &.--half-width {
    width: 50%;
  }
  &.--third-width {
    width: 33%;
  }
  &.--quarter-width {
    width: 25%;
  }
}

.user-template-input-container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: height 0.3s ease-in-out;
}

.user-template-input-container > div:first-child {
  margin-right: 1rem;
}

.user-template-upload-instructions {
  padding-right: 1rem;
  h2 {
    margin-bottom: 1rem;
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  ul {
    margin-left: 2rem;
  }
}

.api-key-form {
  .ovis-btn {
    margin-top: 1rem;
    margin-left: auto;
    width: 100%;
  }
  .key {
    font-size: 0.85rem;
    margin-left: 0.5rem;
  }
}

.org-management-container {
  &_submit-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & > button {
      margin: 2rem 0;
    }
  }
}

.ovis-table-wrapper {
  .user-privs-table {
    overflow: hidden;
    .checkbox-svg {
      width: auto !important;
    }
    .ovis-table-tbody {
      .ovis-table-row {
        height: 1rem !important;
        .ovis-table-cell {
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
        }
      }
    }
    .label-text {
      display: none;
    }
  }
}

.modal-class {
  &.--view-audit-report {
    max-width: 90vw;
    width: 90vw;
  }
}

.access-priv {
  &_select-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    h2 {
      color: #fff;
      margin-right: 1rem;
      font-size: 1.44rem;
      font-weight: 100;
    }
  }
  &_tables {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    .ovis-panel {
      width: 49.5%;
    }
    .ovis-table {
      &-no-data {
        height: 4rem;
      }
      &-wrapper.--loading {
        height: 10.187rem;
        .ovis-table-loader-container {
          height: 15rem;
        }
      }
    }
    &_user-panel {
      .ovis-table-cell:first-child {
        width: 55%;
        min-width: 130px;
      }
    }
    &_dm-panel {
      .ovis-table-cell:first-child {
        width: 33%;
        min-width: 150px;
      }
    }
  }
}

@media (max-width: 1450px) {
  #user-management {
    .org-management {
      .bottom {
        flex-direction: column;
        align-items: flex-start;
        .edit-org-toggle {
          margin-top: 1.1rem;
          width: 100%;
          align-items: flex-end;
          justify-content: flex-start;
          .select-wrapper {
            flex-direction: column;
            align-items: flex-start;
            label {
              margin-bottom: 1.1rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1020px) {
  .access-priv {
    &_tables {
      flex-direction: column;
      .ovis-panel {
        width: 100%;
      }
    }
  }
}
