.fullscreen-redirect {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  text-align: center;
  h3 {
    font-weight: 300;
    margin-bottom: 1rem;
  }
  img {
    width: 100%;
    max-width: 350px;
  }
  .logo-nav {
    margin-bottom: 1rem;
  }
}
