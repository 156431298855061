@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.dm-blogging {
  &_actions {
    display: flex;
  }

  &_likes-icon {
    width: 12px;
    margin: 0 0.25rem;
  }

  &_action {
    display: flex;
    align-items: center;
    margin-right: $spacingDefault;
    button {
      margin-right: $spacingDefault;
      display: flex;
    }
  }
  &_likes {
    .like-btn {
      &.--has-liked {
        svg {
          fill: $ovisPrimary;
        }
      }
    }
    .small-text {
      top: 2px;
    }
  }
  &_toolbar {
    display: flex;
    justify-content: space-between;
    padding: $spacingDefault 0rem;
    &.--side-nav-open {
      .dm-blogging_toolbar-btn button {
        flex-direction: column;
      }
    }
    &-wrapper {
      &.--side-nav-open {
        .dm-blogging_toolbar-btn button {
          flex-direction: column;
          svg {
            margin-right: 0;
            margin-bottom: 0.25rem;
          }
        }
      }
    }

    &-btn {
      flex: 1;
      display: flex;
      p {
        color: $textLight;
      }
      .ovis-loader-container {
        width: 20px;
        height: 20px;
        margin-right: 0.4rem;
        .ovis-loader {
          width: 20px;
          height: 20px;
        }
      }
      > button,
      .dm-blogging-btn {
        display: flex;
        align-items: center;
        padding: 0.25rem;
        justify-content: center;
        border-radius: 6.4px;
        transition: background-color 167ms cubic-bezier(0.4, 0, 0.2, 1);
        width: 100%;
        &:hover {
          background: rgba(0, 0, 0, 0.08);
        }
      }
      svg {
        width: 24px;
        margin-right: 0.5rem;
        fill: $textLight;
      }
    }
  }
  &_totals {
    display: flex;
    justify-content: space-between;
    color: $defaultBorderGray;
    &-likes {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $textDefault;
      &.ovis-btn.--disabled {
        opacity: 1;
        cursor: default;
      }
    }
  }
}

.dm-blog {
  .ovis-modal {
    padding-bottom: 2px;
  }
  &_content {
    p {
      margin-bottom: 0;
    }
    .document-title {
      margin-bottom: 0.5rem;
      font-size: $md-font;
      font-weight: 300;
    }
    .dm-blog_image {
      width: calc(100% + 2rem);
      left: -1rem;
      margin: 2rem 0 1rem;
    }
    .dm-blogging_toolbar-wrapper {
      position: static;
      width: 100%;
    }
  }
}

.dm-blogging_toolbar-btn {
  .ovis-modal {
    padding-bottom: 2px;
  }
}

.share {
  overflow-x: hidden;
  .dm-blogging_toolbar {
    width: 100%;
    left: 0;
  }
  .ovis-tooltip-wrapper {
    display: block;
    *.ovis-tooltip-tip {
      font-size: 0.65rem;
      font-family: inherit;
    }
  }
  &-toolbar {
    .ovis-input {
      cursor: pointer;
    }
    .ovis-input_suffix {
      font-family: $fontWeightMedium;
      cursor: pointer;
      color: $textDark;
    }
  }
  &_content {
    display: flex;
    padding-top: $spacingDefault;
    padding-right: $spacingDefault;
    img {
      width: 250px;
      padding-right: 0.5rem;
      border-right: 1px solid $black80;
      @media (max-width: $sm-max) {
        display: none;
      }
    }
    &-text {
      margin-left: 0.5rem;
    }
  }
  &_user-list {
    margin: $spacingSmall 0;
    max-height: 226px;
    overflow-y: scroll;
    padding-right: $spacingMedium;
    width: 105%;
  }
  &_user {
    cursor: pointer;
    display: flex;
    padding: 0.5rem 0rem;
    align-items: center;
    justify-content: space-between;
    .ovis-checkbox {
      width: auto;
      & > span {
        margin-right: 0;
      }
    }
    &-info {
      display: flex;
      align-items: center;
    }
    &-search {
      margin-bottom: $spacingLarge;
    }
    p:nth-child(1) {
      line-height: 1;
    }
    &-handler {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      width: 75px;
      @include flexCenter;
    }
    &:nth-last-child(1) {
      border-bottom: none;
    }
    .profile-photo {
      margin-right: $spacingMedium;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.comments {
  .--no-default-styles {
    text-decoration: underline;
    padding-left: 0;
    color: $textDefault;
  }
  .ovis-dropdown-container {
    margin-bottom: $spacingDefault;
    .ovis-btn {
      font-size: $fontSM;
    }
    .ovis-dropdown-body {
      padding: 0.25rem;
      .ovis-btn {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
  .ovis-btn.child-comments_view-more-btn {
    margin-left: 3.5rem;
    margin-bottom: $spacingSmall;
    margin-top: -$spacingDefault;
    font-size: 12px;
    padding: 0;
    color: $textLight;
    &:hover {
      text-decoration: underline;
    }
  }
  &_main-input-container {
    padding: 1rem 0;
  }
  &_input {
    &-wrapper {
      display: flex;
      align-items: flex-start;
    }
    .ovis-text-area {
      flex: auto;
      width: auto;
      padding: 0 0 0 0.5rem;
      margin-left: 0.5rem;
      .ovis-text-area_input {
        border: none;
        background-color: $appBackgroundGray;
        border-radius: $borderRadiusMd;
      }
    }
    button {
      margin-top: 0.5rem;
      margin-left: calc(35px + 1rem + 1px);
    }
  }
  &_loading {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    p {
      margin-right: 0.5rem;
      font-size: $fontSM;
      font-family: $fontWeightMedium;
    }
  }
  .view-more {
    font-size: $fontSM;
  }
  .comment {
    margin-bottom: $spacingMedium;
    display: flex;
    align-items: flex-start;
    &.--requesting-delete {
      pointer-events: none;
      opacity: 0.45;
    }
    &_user {
      margin-bottom: 0.5rem;
    }
    &_name {
      font-family: $fontWeightRegular;
    }
    &_info {
      position: absolute;
      right: $spacingDefault;
      top: $spacingDefault;
      display: flex;
      align-items: center;
    }
    &_body-text {
      font-family: $fontWeightLight;
    }
    &_menu {
      margin-left: $spacingSmall;
      display: inline-flex;
      .ovis-dropdown-body {
        padding: $spacingXSmall;
        box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.3);
        &::before {
          right: -4px;
          height: 15px;
          width: 15px;
        }
      }
      .dot-menu-svg {
        cursor: pointer;
      }
      p {
        margin-top: 0;
        font-size: $xs-font;
      }
      svg {
        fill: $defaultBorderGray;
        width: 14px;
      }
      .trash-icon {
        margin-right: $spacingSmall;
        top: -1px;
        path {
          stroke: $textDefault;
        }
      }
      button {
        display: flex;
        align-items: center;
      }
    }
    &_user-photo {
      padding-right: 0.5rem;
    }
    &_body-wrapper {
      width: auto;
      flex: auto;
    }
    &_body {
      border-radius: 0px $borderRadiusRg $borderRadiusRg $borderRadiusRg;
      padding: 12px $spacingMedium 12px;
      margin-left: 0.5rem;
      flex: auto;
      width: auto;
      background: $appBackgroundGray;
    }
    &_date {
      margin-left: $spacingDefault;
    }
    &_toolbar {
      margin-top: $spacingDefault;
      left: -4px;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .dot-divider {
        height: 2px;
        width: 2px;
        border-radius: 100%;
        background: black;
        margin: 0 0.25rem;
        display: inline-block;
        left: -2px;
      }
      .divider {
        margin: 0 0.25rem;
        display: inline;
      }
      .reply-btn {
        padding: 0 $spacingXSmall;
        transition: all 0.15s ease;
        border-radius: 6px;
        font-size: 12px;
        &.--has-liked {
          background-color: $black10;
        }
        &:hover {
          background-color: $black10;
        }
      }
    }
    &_container {
      .comments_input {
        padding-left: 3.2rem;
        margin: 0.25rem 0 1rem;
      }
      .comment_container {
        padding-left: 3.2rem;
      }
    }
  }
}
