/** Default font styles **/
html,
body {
  color: $textDefault;
  font-family: $font-family;
  font-size: $baseSize;
  line-height: 1.6;
  letter-spacing: 0.02em;
  width: 100%;
  @media (max-width: $sm-max) {
    font-size: $baseSizeMobile;
  }
}

a {
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
}

p {
  margin-bottom: 1rem;
  &:nth-last-child(1),
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}

/** Font Classes **/
.page-title {
  font-size: 2rem;
  font-weight: 100;
  line-height: 1.2em;
  margin-bottom: $spacingLarge;
  font-family: $fontWeightRegular;
}

.widget-title,
.section-title {
  color: $textDark;
  font-size: $fontMD;
  font-family: $fontWeightBold;
  line-height: 1.3em;
}

.component-subtitle {
  font-size: 1.44rem;
  font-weight: 200;
}

.document-title {
  font-family: $fontWeightRegular;
  margin-bottom: 0.15rem;
  color: $textDark;
}

.description-text {
  font-size: $sm-font;
  line-height: 1.3;
}

.small-text {
  font-size: $fontSM;
  font-family: $fontWeightRegular;
}

.subscript {
  font-weight: 300;
  font-size: $xssub-font;
}

.button-text {
  /*** Text inside the page links components, buttons, and other toggles ***/
  font-size: 15px;
  font-family: $fontWeightRegular;
  text-transform: uppercase;
  line-height: 1.4em;
  letter-spacing: 0.15em;
  margin-bottom: 0;
}

/** Color Modifiers **/

.--warning {
  color: $alertRed;
}

.--light {
  color: $textLight;
}

.--dark {
  color: $textDark;
}

/** Weight Modifiers **/

.--w-light {
  font-family: $fontWeightLight;
}
.--w-regular {
  font-family: $fontWeightRegular;
}
.--w-medium {
  font-family: $fontWeightMedium;
}
.--w-bold {
  font-family: $fontWeightBold;
}
.--w-x-bold {
  font-family: $fontWeightXBold;
}

/*** Spacing ***/
p.--sb-margin {
  margin-bottom: $spacingSmall;
}

p.--rb-margin {
  margin-bottom: $spacingDefault;
}
