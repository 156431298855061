@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.side-panel-instance {
  position: fixed;
  top: $headerHeight;
  z-index: 9999;
  width: 100%;
  z-index: 10000;
  &.--light {
    .side-panel {
      background: white;
    }
  }
  &.--dark {
    .side-panel {
      background: $baseBlue;
      &_header {
        background: $baseBlue;
      }
    }
    * {
      color: white;
    }
  }
  &.--small {
    max-width: 375px;
  }
  &.--default {
    max-width: 500px;
  }
  &.--large {
    max-width: 600px;
  }
  &.--right {
    right: 0;
    //border-left: 1px solid #797e93;
  }
  &.--left {
    left: 0;
    border-right: 1px solid $defaultBorderGray;
  }
  &.--top,
  &.--bottom {
    max-width: 100vw;
  }
  &.--top {
    border-bottom: 1px solid $defaultBorderGray;
  }
  &.--bottom {
    bottom: 0;
    top: initial;
    border-top: 1px solid $defaultBorderGray;
  }
}
.side-panel {
  background: $baseBlue;
  width: 100%;
  height: calc(100vh - $headerHeight);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  padding-top: 98px;
  &_wrapper {
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_header {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 3;
    padding: 1rem 1.5rem 0 0;
  }

  &_title {
    width: 100%;
    margin: 0.5rem 0 1rem;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      bottom: -0.25rem;
      width: calc(100% + 1rem);
      height: 1px;
      background-color: $secondaryBlue;
    }
  }
}

@media screen and (max-width: $xs-max) {
  .side-panel-instance {
    top: $headerMobileHeight;
    max-width: initial !important;
    width: 101%;
    .side-panel {
      height: calc(100vh - #{$headerMobileHeight} - 2rem - 0.5rem);
    }
  }
}
