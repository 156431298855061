.ovis-alert-text {
  top: -18px;
  max-width: 70%;
}
body .ovis-reporting-table.isChrome tbody tr.selected-row td,
.ovis-reporting-table.isChrome tbody tr.selected-row td:nth-child(1) {
  top: 28px;
}
.carousel-slide {
  & > div {
    width: 100%;
  }
  .--tile-document {
    .doc-title {
      padding-right: 4rem;
    }
  }
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 8rem;
}
.sms-request-message {
  color: white;
}
.quill {
  width: 100%;
  background: white;
  p {
    color: black;
  }
  .ql-container {
    font-family: inherit;
  }
  .ql-clean {
    display: none;
  }
  .ql-picker {
    .ql-picker-label::before {
      position: relative;
      top: -4px;
    }
  }
  strong {
    font-weight: 600 !important;
  }
  .ql-toolbar.ql-snow {
    text-align: left;
    .ql-formats::after {
      display: none;
    }
    &::after {
      display: none;
    }
    .ql-formats {
      margin-right: 5px;
    }
  }
  .ql-editor {
    background: hsla(0, 0%, 75.3%, 0.178);
    min-height: 8rem;
    resize: vertical;
  }
}

.auth-beacon {
  display: none;
}
.beacon-container {
  display: none;
}

.ovis-dropdown-container {
  display: inline-block;
}

.side-panel-instance {
  top: $headerHeight !important;

  .side-panel {
    *:after {
      background-color: $defaultBorderGray !important;
    }
    padding-top: 110px !important;
    height: calc(100vh - $headerHeight) !important;
  }
  .side-panel,
  .side-panel_header {
    background: white !important;
    button {
      pointer-events: initial;
    }
  }
}

@media (max-width: $xs-max) {
  .ovis-panel {
    padding: 0 $spacingSmall;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  font-weight: 300 !important;
  font-family: $font-family !important;
  letter-spacing: 0.02em;
}
