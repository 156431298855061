@import '../abstracts/variables';

.widgets-container {
  width: 100%;
  max-width: 90rem;
  display: grid;
  grid-template-columns: 63.1% 37%;
  grid-template-rows: calc(100vh - 10.5rem) 0;
  gap: 0.5rem;
  overflow: hidden;

  @media screen and (max-width: $sm-max) {
    display: flex;
    flex-direction: column;

    #circle-arrow {
      bottom: 2.5rem;
    }
  }
  .ovis-panel {
    margin: 0 0 1.5rem;
  }
}

.widget-content {
  overflow: visible;
  overflow-y: scroll;
  flex-grow: 1;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .widget-content {
    height: 29.5rem;
  }
}
