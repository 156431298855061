.expand-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  height: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &::after,
  &::before {
    position: absolute;
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: background, transform, top, color, opacity;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1),
      cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1),
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-delay: 0s, 0s, 0s, 0s;
    content: '';
    background: #fff;
    opacity: 0.55;
  }
  &::before {
    left: 0px;
    transform: rotate(45deg);
  }
  &::after {
    right: 0px;
    transform: rotate(-45deg);
  }
  &.--is-open,
  &.--open {
    &::before,
    &::after {
      opacity: 1;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}
