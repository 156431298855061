@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

#file-manager {
  .ovis-drag-drop_zone {
    & > p:nth-last-of-type(1) {
      margin-bottom: 1.5rem;
    }
  }
  .file-manager {
    &_container {
      width: 100%;
    }

    .ovis-modal {
      height: auto;
    }

    &_upload {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
    }

    &_upload > button {
      align-self: end;
      margin-top: 1rem;
    }

    &_upload-context {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .multi-upload {
      display: flex;
      justify-content: center;
    }

    .ovis-drag-drop {
      width: 100%;
      max-width: 50rem;
    }

    .ovis-tooltip-wrapper {
      display: flex;
      align-items: center;
    }
    &_loader-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      display: none;
      background-color: rgba(230, 230, 230, 0.5);
      z-index: $componentOverlayZIndex;

      &.--active {
        display: flex;
      }
    }
    &_icon {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    &_controls {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #6f7b8a;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    &_previous {
      cursor: pointer;
    }

    &_previous > &_icon {
      width: 2rem;
      height: 2rem;
    }

    &_current-dir {
      display: flex;
    }

    &_current-dir > * {
      margin-right: 1rem;
    }

    &_current-dir > div {
      display: flex;
      align-items: center;
    }

    &_path {
      min-width: 25vw;
      background-color: white;
      padding: 0 0.8rem;
      border-radius: 0.2rem;
      border: 1px solid #dadce0;
    }

    &_directories {
      display: flex;
      flex-wrap: wrap;
    }

    &_directory-add-wrapper {
      &.--expanded {
        width: 100%;
      }
    }

    &_directory {
      display: flex;
      background-color: #fff;
      cursor: pointer;
      background: $appBackgroundGray;
      padding: $spacingDefault;
      height: auto;
      margin-right: $spacingDefault;
      font-size: $fontSM;
      &.--add {
        display: flex;
        flex-direction: column;
        border-radius: $borderRadiusMd;
        svg {
          margin-right: 0;
        }
        .ovis-input_instance {
          margin-right: $spacingDefault;
          .ovis-input_container {
            background: $appBackgroundGray;
          }
        }
        p {
          width: 100%;
          font-size: $fontSM;
          padding-top: $spacingSmall;
        }
      }

      &.--expanded {
        cursor: auto;
        border-radius: 0;
        padding: $spacingDefault 0 0;
        background: transparent;
        padding-top: $spacingMedium;
      }
    }

    &_directory > svg {
      margin-right: 0.5rem;
      max-width: 1.5rem;
      max-height: 1.5rem;
      padding: 0.25rem;
      background: $black10;
      border-radius: 100%;
    }

    &_directory-form {
      display: flex;
      width: 100%;

      .ovis-btn {
        &.--small {
          height: 2.2rem;
          width: auto;
        }
      }
      .ovis-btn[type='submit'] {
        margin: 0;
      }
    }

    &_files {
      display: flex;
      flex-wrap: wrap;
      gap: $spacingMedium;
      margin-top: $spacingMedium;
      & > * {
        margin-bottom: 0.8rem;
      }
    }

    &_file {
      background-color: #fff;
      border-radius: 0.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0rem 0.5rem 0.5rem 0rem;
      border: 1px solid #dadce0;
      width: 10rem;
      height: 12rem;

      .file-icon-render {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8rem;
        width: 100%;
        background-color: rgba(230, 230, 230, 0.5);
        svg {
          width: 50%;
          height: 50%;
        }
      }
    }

    &_file-details {
      p {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;
      }
      width: 100%;
      padding: 0.6rem;
      border-top: 1px solid #dadce0;
    }

    &_file-actions {
      display: flex;
      margin-top: 0.5rem;
      justify-content: space-around;

      .ovis-preview-button {
        display: flex;
        align-items: center;
        max-width: 1.5rem;
        max-height: 1.5rem;
      }

      .ovis-tooltip-wrapper > div {
        display: flex;
        align-items: center;
      }
    }

    &_file-actions > .ovis-tooltip-wrapper {
      cursor: pointer;
      display: flex;
    }
  }
}

@media screen and (max-width: $xs-max) {
  .file-manager {
    &_files {
      & > * {
        width: 33%;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .file-manager {
    &_files {
      & > * {
        width: 50%;
      }
    }
  }
}
