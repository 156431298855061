.--skeleton {
  // background-color: rgba(187, 187, 187, 0.856);
  overflow: hidden;
  opacity: $skeletonOpacity;
}
.skeleton-gradient {
  position: absolute;
}
.skeleton-gradient {
  &.--slide-down {
    left: -25%;
    height: 200%;
    width: 150%;
    top: -250%;
    transform: rotate(-16deg);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.2),
      rgba(0, 0, 0, 0)
    );
    animation: slideDown 2s cubic-bezier(0.4, 0, 0.2, 1) 0.2s infinite;
  }
}
.skeleton-gradient {
  &.--slide-across {
    left: -200%;
    height: 100%;
    width: 300%;
    top: 0%;
    &.--dark {
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0),
        rgba(255, 255, 255, 0.17),
        rgba(0, 0, 0, 0)
      );
    }
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.2),
      rgba(0, 0, 0, 0)
    );
    animation: slideAcross 2s linear 0.2s infinite;
  }
}

@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}

@keyframes slideAcross {
  0% {
    left: -300%;
  }
  100% {
    left: 100%;
  }
}
