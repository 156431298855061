// Navigation Management Modal Forms
.contain.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-row {
    .input-container {
      display: flex;
      align-items: center;
    }
    .input-container {
      min-width: 20rem;
      width: 100%;
    }
  }
}
.table-loader {
  background: rgba(123, 128, 134, 0.2);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.nav-manage {
  .panel {
    min-height: 45vh;
    margin-bottom: 3vh;
  }
  .nav-org.page-sub-title {
    width: 90%;
    display: flex;
    justify-content: space-between;
    .page-sub-title-box {
      width: 33%;
      display: flex;
    }
  }
  .upload .form-col.input-container {
    flex-direction: row !important;
    align-items: center !important;
  }
}
.nav-orgs-org-select {
  width: 100%;
  margin-bottom: 0.8em;
}

// Test for possible table sorted loader
.title-wrap {
  display: flex;
  align-items: center;
}

.invisible-form {
  display: none;
}
