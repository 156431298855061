@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

$toggleSize: 1.3rem;
$hoverColor: darken($navigationBackground, 10%);

.App {
  .side-nav {
    &_container {
      position: fixed;

      display: flex;
      min-height: calc(100vh - #{$headerHeight});
      margin: 0;
      flex: 1;
      $navigationBackground-space: nowrap;
      z-index: $sideNavContainerZIndex;
      left: 0;
      top: $headerHeight;
      transition: left 0.2s ease;
      .menu_submenu_title {
        padding-left: 0;
      }
      .menu {
        &_submenu_title,
        &_item {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        &_submenu {
          background: $navigationBackground;
          .menu_item {
            .side-nav-element-container.--level-two {
              background: $navigationBackground;
              &:before {
                @include pseudo(5px, 'auto', $left: 0, $top: 50%, $y: -50%);
                background: $baseBlue;
              }
              .side-nav-element {
                height: auto;
                line-height: 25px;
                padding: 0.4rem 0 0.4rem 33px;
              }
            }
          }
          &.--is-open {
            .menu_submenu_title {
              .side-nav-element-container {
                .icon-link-spacer-container {
                  .side-nav-icon {
                    &.rollover {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.hidden {
        left: -310px;
      }

      .side-nav-panel {
        border-right: 1px solid $defaultBorderGray;
        color: $navigationBackground;
        display: flex;
        flex-direction: column;
        $navigationBackground-space: nowrap;
        background: $navigationBackground;
        height: 100%;
        max-width: 3.3rem;
        width: 300px;
        position: absolute;
        left: 0;
        overflow: hidden;
        transition: max-width 0.25s ease;
        .expand-icon {
          opacity: 0;
          z-index: 10;
          top: 55%;
          left: 275px;
          &:before,
          &:after {
            background: $textDefault;
          }
        }
        .side-nav-element {
          &_title {
            opacity: 0;
          }
        }
        .wrapper {
          width: 310px;
        }
        &.--open {
          max-width: 300px;
          transform: translateX(0rem);
          z-index: $sideNavPanelOpenZIndex;
          .wrapper {
            overflow-x: hidden;
          }
          .expand-icon {
            opacity: 1;
          }
          .side-nav-element {
            &_title {
              opacity: 1;
            }
          }
        }
        .icon-link-spacer-container {
          width: 3.3rem;
          margin-right: 0.3rem;
          > div {
            width: 100%;
          }
        }
      }

      .side-nav-element-container,
      .side-nav-element {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        font-weight: light;
        font-size: 0.9rem;
        text-transform: capitalize;
        color: $textDefault;
        cursor: pointer;
        text-overflow: ellipsis;
        $navigationBackground-space: pre-wrap;
        text-align: left;
        width: 300px;
        &_title-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 0.7rem;
          max-height: 3.5rem;
        }
        &.active {
          z-index: 1;
        }
        .menu_submenu {
          .menu_item {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            .side-nav-element {
              margin-left: 1rem;
              padding-right: 0.7rem;
              &_title-container {
                width: 100%;
              }
              &_title {
                width: 100%;
                &:hover {
                  color: $navigationBackground;
                }
              }
              &:hover {
                border-left: 1px solid $navigationBackground;
                background-color: $navigationBackground;
              }
            }
          }
          &.--is-open {
            padding: 0;
          }
        }
      }

      .side-nav-element-container {
        background: $navigationBackground;
        transition: background-color 0.15s, color 0.15s;
        &:before {
          @include pseudo(5px, 'auto', $left: 0, $top: 50%, $y: -50%);
          background: $baseBlue;
        }
        a,
        button {
          width: 100%;
        }
        &.active {
          &:before {
            padding: 29px 0;
          }
        }
        &:hover {
          background-color: $hoverColor;
        }
        .auth-required-link {
          @extend .side-nav-element;
        }
        .menu_submenu {
          width: 100%;
          z-index: $menuSubMenuZIndex;
          &.--is-open {
            overflow-y: auto;
            max-height: 300px;
          }

          &_title {
            padding-left: 0;
            display: flex;
            justify-content: flex-start;
            .menu_icon {
              display: flex;
              justify-self: flex-start;
              width: 3rem;
              margin-top: 0;
            }
          }
        }
        &.--level-two {
          .side-nav-element {
            height: 2.8rem;
          }
        }
        &.--level-three {
          padding-left: 1rem;
          .side-nav-element {
            height: 2.8rem;
          }
        }
      }
      .side-nav-element .link-wrapper,
      .auth-required-link {
        height: 2rem;
        text-align: left;
        align-items: center;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 1.2rem;
        &.nest {
          height: 1.5em;
          font-size: 0.9em;
        }
      }

      a,
      button {
        transition: background-color 0.15s, color 0.15s;
        min-width: 3.4rem;
        max-width: 3.4rem;
        display: flex;
        justify-content: center;
      }
      .side-nav-icon {
        width: 100%;
        background-color: transparent;
        z-index: $sideNavIconZIndex;
        pointer-events: none;
      }
      .icon-preload {
        display: none;
        position: absolute;
        width: 0px;
        height: 0px;
      }

      @media screen and (min-height: 0px) and (max-height: 610px) {
        .side-nav-element-container {
          .menu_submenu {
            &.--is-open {
              max-height: 55vh;
            }
          }
        }
      }

      @media screen and (max-height: 824px) {
        .side-nav-panel {
          max-width: 52px;
          &.--open {
            max-width: 300px;
          }
        }
        &_toggle {
          left: 39px;
          &.--active {
            left: 288px;
          }
        }
      }
      @media screen and (min-height: 0px) and (max-height: 824px) {
        .side-nav-element,
        .side-nav-icon {
          height: 3.1rem;
          padding: 0.9em 0;
          font-size: 0.75rem;
        }
        .side-nav-icon {
          padding: 0.8rem 0;
        }
        .side-nav-element {
          &_title {
            margin-left: 0.3rem;
            &.--level-two {
              margin-left: 0.3rem;
            }
          }
          &_title-container {
            max-height: 300px;
            margin-left: -0.5rem;
            &.--item {
              font-size: 0.9rem;
              margin-left: -0.5rem;
            }
          }
        }

        .side-nav-element-container {
          &.--level-two {
            .side-nav-element {
              height: 2.2rem;
            }
          }
        }
      }
      @media screen and (min-height: 825px) and (max-height: 899px) {
        .side-nav-element .link-wrapper,
        .side-nav-element-container .auth-required-link,
        .side-nav-icon {
          height: 4em;
          padding: 0.9em 0.9em 0.9em 0.9em;
          font-size: 0.83rem;
        }
      }
      @media screen and (min-height: 900px) {
        .side-nav-element .link-wrapper,
        .side-nav-element-container .auth-required-link,
        .side-nav-icon {
          padding: 0.9rem;
          height: 3.65rem;
          font-size: 0.96rem;
        }
        .side-nav-icon {
          padding: 0.9rem 0;
        }
      }
    }
    &_toggle {
      position: absolute;
      z-index: $toggleNavContainerZIndex;
      cursor: pointer;
      top: 2px;
      left: 42px;
      transition: left 0.25s ease;
      @include flex-center;
      align-items: center;
      border-radius: $borderRadiusCircle;
      width: $toggleSize;
      height: $toggleSize;
      background-color: $textDefault;
      button {
        min-width: $toggleSize;
        height: $toggleSize;
        max-width: $toggleSize;
        border-radius: $borderRadiusCircle;
        padding: 0;
      }
      svg {
        width: 10px;
        height: 10px;
      }
      &.--active {
        left: 290px;
      }
      &.--fixed {
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: $xs-max) {
    .side-nav-container {
      top: $headerMobileHeight;
      min-height: calc(100vh - #{$headerMobileHeight});
    }
  }
}
