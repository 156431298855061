.export {
  fill: none;
  stroke: $baseBlue;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.25px;
}

.export-d {
  fill: none;
  stroke: $baseBlue;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.25px;
}

.icon-dropdown-icon.--hover {
  path,
  circle {
    fill: #4eaad5;
    stroke: none;
  }
  .st1 {
    fill: none;
    stroke: none;
  }
}
