.panel-component,
.panel {
  box-shadow: 2px 2px 5px rgb(27, 27, 27);
  width: 100%;
  background: #f1f3f5f3 no-repeat padding-box;
  justify-content: space-around;
  padding: 0 1.5rem;
  margin: 0 0 1rem;
  color: #021d42;

  .panel-item {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    flex-wrap: nowrap;
    height: 100%;
    > * {
      margin-right: 1.5rem;
    }
    > *:nth-last-child(1) {
      margin-right: 0;
    }
    &.column {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &.light {
    .panel-title {
      color: #021d42;
    }
  }

  .panel-item.dividers:not(:last-child) {
    border-bottom: 1px solid #6f7b8a;
    // padding-bottom: 1.5rem;
  }
}
.panel-title {
  * {
    margin-right: 1rem;
  }
}
.panel-component.column {
  flex-direction: column;
  justify-content: flex-start;
}

.panel-component.dark {
  background: #0f2139e5 no-repeat padding-box;
  color: white;
}

.panel-admin-header-buttons {
  justify-content: flex-start;
}
.panel-admin-header-inputs {
  justify-content: space-between;
}

.panel-admin-header-buttons,
.panel-admin-header-inputs {
  width: 100%;
  display: flex;
  * {
    margin-right: 1rem;
  }
  *:last-child {
    margin-right: 0;
  }
}

// Generic panel styles
.panel {
  background: #f1f3f5f3 no-repeat padding-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1.5em;
  align-self: center;
  // max-width: 84rem;
  margin: 0 0 1rem;
  color: #021d42;
}
.panel-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.panel.column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-around;
}
.panel.dark {
  background: #0f2139e5 no-repeat padding-box;
  color: white;
}

.open-panel {
  padding-top: 1.5rem;
  .close-btn {
    top: -1.75rem;
    right: 0rem;
    position: absolute;
  }
}
.panel-title {
  font-size: 2.22rem;
  font-weight: 200;
  line-height: 1.075em;
  cursor: default;
}
.panel-multi-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ovis-btn {
    &.--secondary {
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 766.99px) {
  .panel {
    padding: 3rem 1rem;
  }
  .panel-title {
    font-size: 1.5rem;
  }
  .open-panel {
    .close-btn {
      height: 25px;
      width: 25px;
      top: 1rem;
      right: 1rem;
    }
  }
}
