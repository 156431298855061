.toggle-plus {
  height: 25px;
  width: 25px;
  line {
    stroke: $secondaryBlue;
    transition: 0.3s ease all;
    stroke-dasharray: 30px;
    stroke-dashoffset: 0px;
  }
  line:nth-child(1) {
    transition: 0.3s ease stroke, 0.3s ease stroke-dashoffset;
  }
  &.--is-open {
    line {
      stroke: white;
    }
    line:nth-child(1) {
      stroke-dasharray: 30px;
      stroke-dashoffset: -60px;
    }
    line:nth-child(2) {
      stroke-dasharray: 30px;
      stroke-dashoffset: -30px;
    }
  }
}
