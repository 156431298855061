@import '../abstracts/variables';

$mobileMenuWidth: 100vw;

.mobile-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  height: $headerMobileHeight;
  width: 100vw;
  display: flex;
  flex-direction: column;
  z-index: $mobileheaderBarZIndex;
  .profile-img {
    background-color: white;
    height: auto;
    width: 2.5rem;
    min-height: initial;
    min-width: initial;
    height: 2.5rem;
    border-radius: 2rem;
  }
  .mobile-header-bar {
    display: flex;
    padding: 0rem $spacingDefault 0 $spacingSmall;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    height: $headerMobileHeight;
    width: 100vw;
    top: 0;
    background-color: white;
    z-index: $mobileheaderBarZIndex;
    border-bottom: $border;
    .logo-container {
      display: flex;
      flex: 1.5;
      justify-content: flex-end;
      .logo-nav {
        width: 2rem;
      }
    }
    .header-icons-container {
      display: flex;
      flex-direction: row;
      flex: 3;
      align-items: center;
      justify-content: space-evenly;
      height: $headerMobileHeight;
      .header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        svg {
          pointer-events: none;
          height: 1.5rem;
        }
        p {
          margin-bottom: 1rem;
        }
        .header-icon-title {
          pointer-events: none;
          position: absolute;
          bottom: -2.3rem;
          font-size: 0.7rem;
          font-family: $fontWeightRegular;
          color: grey;
        }
      }
      .alert-toggle-icon {
        margin-left: 0;
      }
    }
  }
}

.menu-container {
  position: fixed;
  width: $mobileMenuWidth;
  height: calc(100vh - #{$headerMobileHeight} - #{$bottomNavMobileHeight});
  background-color: $baseBlue;
  z-index: $menuContainerZIndex;
  top: $headerMobileHeight;
  transform: translate(-$mobileMenuWidth);
  transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 4rem;

  &.--visible {
    transform: translate(0);
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .menu {
    background-color: inherit;
    width: $mobileMenuWidth;
    > ul .menu-item {
      padding-left: 1.5rem;
      line-height: 50px;
      a {
        color: white;
      }
    }
    > ul .submenu_title {
      padding-left: 1.5rem;
      line-height: 50px;
    }
    .menu_item.--is-active {
      background: $secondaryBlueAccent;
    }
    .menu_submenu {
      .menu_submenu_items_container {
        p,
        a,
        button {
          margin: 0;
          color: rgba(255, 255, 255, 0.65);
        }
      }
      .submenu_title {
        cursor: pointer;
      }
      &.--is-open {
        .expand-icon {
          &::before,
          &::after {
            opacity: 1;
          }
          &::before {
            transform: rotate(-45deg);
          }
          &::after {
            transform: rotate(45deg);
          }
        }
      }
      .submenu_items {
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
      }
      .submenu_items_container {
        margin-bottom: 0;
        list-style-position: inside;
        background: #000c17;
      }
      .menu-item {
        height: 40px;
        line-height: 40px;
        font-size: 0.8rem;
        padding-left: 4.05rem;
        background-color: #0c3163;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &.--is-active {
          background-color: $secondaryBlueAccent;
        }
        a {
          color: rgba(255, 255, 255, 0.85);
        }
        &:hover {
          color: white;
        }
      }
    }
    &_icon {
      width: 50px;
      height: auto;
      display: flex;
      align-items: center;
    }
    .menu_item,
    .menu_submenu_title {
      padding-left: 0.5rem !important;
      display: flex;
      align-items: center;
      color: white !important;
      font-size: 1rem;
      height: 50px;
      line-height: 50px;
      &::after {
        border: none;
        display: none;
      }
      &.active {
        background: transparent;
      }
    }
    .mobile-nav-icon {
      width: 2rem;
      margin-right: 1rem;
    }
    .mobile-nav-svg {
      padding-left: 0.5rem;
      height: 20px;
    }
    .shadow {
      z-index: $mobileNavContainerShadowZIndex;
    }
    .nav-user {
      border-right: none;
      border-left: none;
      color: white;
      @include flexCenter();
      a {
        color: $baseBlue;
      }
      p {
        margin: 0 1.5rem;
        color: white;
      }
      .nav-user-dropdown {
        width: 8rem;
        .ovis-dropdown-body {
          width: 8rem;
          right: -2rem;
        }
        .profile-menu {
          .profile-menu-item {
            display: flex;
            align-items: center;
            & > a {
              padding: 0.5rem;
            }
            & > button {
              padding: 0.5rem;
            }
          }
        }
      }
    }
    .logout-btn {
      color: rgb(192, 46, 46);
    }
  }
}

@media screen and (max-width: $xs-max) {
  .mobile-nav-container {
    .nav-user {
      padding: 0;
      .nav-user-dropdown {
        margin-top: -1.8rem;
        left: -1rem;
        width: 10rem;
      }
    }
  }
}
