@mixin pseudoElement($content: '') {
  content: $content;
  display: inline-block;
}

@mixin absolutePseudoElement(
  $content: '',
  $top: 0,
  $bottom: 'initial',
  $left: 0,
  $right: 'initial'
) {
  @include pseudoElement($content);
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin floatingIcon() {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 100000;
  transform: translate(310px);
  transition: transform 0.15s ease;
}

@mixin borderRadiusBottom {
  border-radius: 0px 0px 8px 8px;
}

@import './variables';
/*** Transforms ***/
@mixin translate($x: 0, $y: 0) {
  transform: translate($x, $y);
}

/*** Positions ***/

// Absolute Positions
@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  @include translate(-50%, -50%);
}

@mixin absolute-center-left {
  position: absolute;
  left: 0;
  top: 50%;
  @include translate(0%, -50%);
}

@mixin absolute-center-right {
  position: absolute;
  left: initial;
  right: 0;
  top: 50%;
  @include translate(0%, -50%);
}

@mixin absolute(
  $left: 0,
  $right: 0,
  $top: 0,
  $bottom: 0,
  $transformX: 0,
  $transformY: 0
) {
  position: absolute;
  left: $left;
  right: $right;
  top: $top;
  bottom: $bottom;
  @include translate($transformX, $transformY);
}

// Flex Positions
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

@mixin flex-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/*** Borders and Box shadows ***/
@mixin defaultBoxShadow($color: rgba(0, 0, 0, 0.16)) {
  box-shadow: $color 0px 1px 4px;
}

@mixin defaultFloatingContainerBorder($color: rgba(0, 0, 0, 0.16)) {
  box-shadow: $color 0px 1px 4px;
  border-radius: $borderRadiusMd;
}

@mixin defaultContainerBorder($color: rgba(0, 0, 0, 0.16)) {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: $borderRadiusSm;
}

/*** Sizing ***/
@mixin size($width: initial, $height: initial) {
  width: $width;
  height: $height;
}

/*** Psuedos ***/
@mixin pseudo(
  $width: 100%,
  $height: 100%,
  $display: block,
  $pos: absolute,
  $content: '',
  $left: initial,
  $right: initial,
  $top: initial,
  $bottom: initial,
  $x: 0,
  $y: 0
) {
  content: $content;
  display: $display;
  position: $pos;
  @include size($width, $height);
  @include absolute($left, $right, $top, $bottom, $x, $y);
}

/*** Transitions ***/
@mixin backgroundTransition($timing: $transTimingLong, $easing: $timingStatic) {
  transition: background-color $timing $easing;
}

/*** Screen Sizes ***/

@mixin mobile {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin largeTablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1536px) {
    @content;
  }
}

@mixin xlDesktop {
  @media (max-width: 1900px) {
    @content;
  }
}
