@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.search-wrapper {
  display: flex;
  align-items: center;
  border-radius: $borderRadiusMd;
  transform: translate(10%, -38%);
  padding: 0.5rem;
  max-width: 100%;
  min-width: 23rem;
  background: $appBackgroundGray;
  input {
    font-size: 1rem;
  }
  .search-input {
    display: flex;
    align-items: center;
    flex: 6;
  }
  input,
  .close-btn {
    transition: background-color 0.1s ease, opacity 0.3s ease;
  }
  .close-btn:hover {
    background-color: $alertRedAccent !important;
    svg {
      rect {
        fill: white;
      }
    }
  }
  .close-btn {
    flex: 1;
    background: $alertRed;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    position: absolute;
    right: 0.5rem;
    transform: translate(0, -50%);
    padding: 0;
    svg {
      width: 12px;
      height: 12px;
      fill: white;
    }
  }
  .search-icon {
    cursor: pointer;
    z-index: $searchIconZIndex;
    width: 30px;
    margin-right: 0.5rem;
    top: 0px;
    height: 30px;
    svg {
      width: 30px;
    }
  }
  &.--closed {
    background: transparent;
    .close-btn,
    input {
      opacity: 0;
    }
  }
  &.--open {
    input,
    .close-btn {
      opacity: 1;
      outline: transparent;
    }
    input {
      border: none;
    }
  }
}

@media (max-width: 767px) {
  .search-wrapper {
    transform: translate(0);
    margin: 0rem 0 1.5rem;
    width: 100%;
    &.--open {
      position: fixed;
      left: 0;
      top: 0;
      transform: translate(0, 0);
    }
    &.--closed {
      max-width: 100%;
      background: $backgroundGrayLight;
      input,
      .close-btn {
        opacity: 1;
        outline: transparent;
      }
      .search-icon {
        top: 5px;
        svg {
          circle,
          line {
            stroke: $defaultBorderGray;
          }
        }
      }
    }
  }
}
