.parsers {
  max-width: 80rem;
}
.parser-upload-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .parser-selected-file-label-container {
    display: flex;
    min-width: 13rem;
    .parser-selected-file-label {
      flex-grow: 2;
      display: flex;
      border-bottom: 1px solid white;
      margin-left: 0.5rem;
    }
  }
  .file-label {
    height: 4rem;
  }
  .parser-upload {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    .ovis-btn {
      margin-left: 1rem;
    }
  }
  .page-sub-title {
    margin: 0 3rem 0 0;
  }
}
.mapping-table-container {
  width: 40rem;
  margin-bottom: 2rem;
}
.parser-error-title {
  color: red;
}
.parser-error-text {
  color: black;
}

.instance_delete {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}
