@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.flex-container {
  display: flex;
  margin-bottom: $spacingMedium;
  max-width: 100%;
  gap: 1%;
  &.side-scroll {
    overflow-x: scroll;
  }
}
