@import '../../styles/abstracts/variables';

.profile.content {
  max-width: 80em;
}
.user-profile {
  .bearer-token, .edit-profile_btn {
    svg {
      margin-left: $spacingSmall;
      transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  p {
    margin-bottom: 0;
    color: $textDark;
  }
  .label {
    font-size: 14px;
    color: $textDefault;
    font-family: $fontWeightMedium;
    margin: 0 $spacingSmall 0 0;
  }
  .profile-photo.--large {
    margin: auto;
    width: 250px;
    height: 250px;
    margin-bottom: $spacingDefault;
  }
  .flex-container,
  .user-profile_row {
    margin-bottom: $spacingMedium;
  }
  .user-profile_phone {
    gap: 5%;
  }
  .custom-html-wrapper {
    p {
      margin-bottom: $spacingDefault;
    }
  }
  .section-title {
    margin-bottom: $spacingDefault;
    border-radius: $borderRadiusRg;
    background: $ovisPrimaryBack;
    color: $ovisPrimary;
    width: fit-content;
    padding: $spacingXSmall $spacingDefault;
  }
  .edit-profile_btns-container {
    flex-wrap: wrap;
  }
  .ovis-btn.--fill-width,
  .ovis-tooltip-wrapper {
    flex: initial;
    width: 49.5%;
    justify-content: center;
    margin-bottom: 1%;
    svg {
      width: fit-content;
      margin-left: $spacingDefault;
    }
  }
  .ovis-tooltip-wrapper {
    .ovis-tooltip-container {
      left: 0;
      .ovis-tooltip-tip {
        &::before {
          display: none;
        }
      }
    }
    > button {
      width: 100% !important;
    }
  }
  .wrapper {
    padding-left: $spacingDefault;
  }
}

.profile-edit_side-panel {
  .ovis-image-upload-container{
    align-items: center;
  }
  &.side-panel-instance {
    .side-panel, .side-panel_header {
      background-color: $appBackgroundGray !important;
    }
    .side-panel_wrapper {
      padding: 0 .33rem;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .ovis-input, .ovis-form-col {
      color: #09264c;
    }
    @media screen and (max-width: 767px) {
      max-width: unset;
      margin-top: 2rem;
      .ovis-form-row {
        .ovis-form-label {
          margin-left: 0 !important;
        }
        .ovis-form-col {
          padding-left: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .profile-img-and-file-upload {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    min-width: 20rem;
    .profile-img-container {
      width: 450px;
      height: 450px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 7px rgb(27, 27, 27);
      border-radius: 1rem;
    }
    .profile-img-large {
      max-height: 16rem;
      max-width: 18rem;
      border-radius: 1rem;
    }
    .profile-menu-item {
      padding: 0;
      width: 9rem;
      a {
        padding: 1rem;
        width: 100%;
        text-align: center;
      }
    }
    .file-label {
      top: 85%;
      left: 15.5rem;
      display: block;
      text-align: center;
      background-color: $secondaryBlue;
      transition: background-color 0.18s;
      &:hover {
        background-color: lighten($secondaryBlue, 10%);
      }
      &:active {
        background-color: lighten($secondaryBlue, 20%);
      }
    }
    .file-label,
    .remove-img-btn {
      line-height: 170%;
      border: none;
      cursor: pointer;
      border-radius: 1rem;
      width: 2rem;
      height: 2rem;
      position: absolute;
      font-size: larger;
      box-shadow: 2px 2px 5px rgb(27, 27, 27);
    }
    .remove-img-btn {
      display: flex;
      justify-content: center;
      top: 0.3rem;
      left: 15.5rem;
      background-color: rgba(255, 0, 0, 0.472);
      color: white;
      margin: 0;
      &:hover {
        background-color: lighten(rgba(255, 0, 0, 0.472), 10%);
      }
    }
  }
  .panel {
    align-items: flex-start;
    align-self: unset;
    min-height: 45vh;
    margin-bottom: 3vh;
  }
  .panel.password-panel {
    min-height: unset;
  }
  .password-panel {
    margin-top: 3rem;
    max-width: 45rem;
  }
  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $inputFileZIndex;
  }
  .change-pass_bottom {
    display: none;
  }
  .ovis-image-preview {
    margin: auto;
    max-width: 250px !important;
    height: 250px;
    &_inner {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .change-pass_top {
    display: flex;
    gap: 5%;
  }
}


