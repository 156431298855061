@import './abstracts/variables';

.advisor-table {
  animation: fadeInSkeleton ease 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.wrapper {
  .bar {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 25px;
    background: $backgroundGrayLight;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInSkeleton {
  0% {
    opacity: $skeletonOpacity;
  }
  100% {
    opacity: 1;
  }
}

/**** Global Page Styles ****/
.content-container.--modal-open {
  max-height: 100vh;
  position: fixed;
}

br {
  line-height: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

::placeholder {
  color: grey !important;
}

/* A elements that don'
  t have a class get default styles * / a: not([class]) {
  text-decoration-skip-ink: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
}

button,
input[type='checkbox'] {
  cursor: pointer;
}
input[type='checkbox']:focus {
  border: 0.5px grey solid;
  height: 1em;
  width: 1em;
  outline: none;
}
button {
  &:active,
  &:focus {
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
}
button:disabled,
.btn-primary:disabled,
.btn-secondary:disabled {
  // background-color: grey;
  cursor: pointer;
  pointer-events: none;
  opacity: 0.5;
  &:hover {
    pointer-events: none;
  }
}
.link-to {
  font-size: 1rem;
  display: flex;
  justify-content: right;
  margin-bottom: 1rem;
  cursor: pointer;
  svg {
    width: 1.5rem;
    margin-left: 1rem;
  }
  &.left {
    svg {
      margin: 0 1rem 0 0;
      transform: rotate(180deg);
    }
    justify-content: left;
  }
  &.white {
    fill: white;
    color: white;
  }
}
.left-arrow {
  margin: 0 1rem 0 0;
  transform: rotate(180deg);
}
.down-arrow {
  margin: 0 1rem 0 0;
  transform: rotate(90deg);
}
.up-arrow {
  margin: 0 1rem 0 0;
  transform: rotate(270deg);
}
/******* Text Styles *******/

/* Return Links/Small Title Text */

.table-action-icon {
  margin: 2px;
  &:active,
  &:focus {
    outline-style: none;
    -moz-outline-style: none;
    border: none;
  }
}

/*** Basic Layouts ***/
.contain {
  width: 100%;
  // max-width: 1400px;
  .row.no-gutters {
    > div {
      margin-right: 0rem;
    }
  }
  .row:nth-last-child(1) {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    align-items: center;
    .col-2 {
      flex-grow: 2;
      flex-basis: 15%;
    }
    .col-3 {
      flex-grow: 3;
    }
    .col-4 {
      flex-grow: 4;
    }
    .col-5 {
      flex-grow: 5;
    }
    .col-6 {
      flex-grow: 6;
    }
    .col-7 {
      flex-grow: 7;
    }
    .col-8 {
      flex-grow: 8;
    }
  }
}

/*** Component Styles ***/
.btn,
.btn-primary,
.btn-secondary {
  svg {
    margin-left: 16px;
    top: 4px;
    width: 36px;
    transition: transform 0.2s ease;
    .cls-1 {
      fill: white;
    }
  }
  &:active,
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
  }
}
.btn,
.btn-primary {
  background: $baseBlue;
  &:hover {
    background-color: lighten($baseBlue, 10%);
    svg.right-arrow {
      transform: translate(5px);
    }
    svg.left-arrow {
      transform: translate(-5px) rotate(180deg);
    }
    svg.down-arrow {
      transform: translate(0, 5px) rotate(90deg);
    }
    svg.up-arrow {
      transform: translate(0, -5px) rotate(270deg);
    }
  }
  &.--transparent {
    background: transparent;
    box-shadow: inset 0px 0px 1px 1px white;
    &:hover {
      background: $secondaryBlue;
    }
  }
}
svg.move-icon {
  cursor: move;
}

/** To fix Okta login buttons **/
.login-page {
  .loginContainer #okta-sign-in {
    .button-primary.button {
      @extend .btn-primary;
      max-width: initial;
    }
  }
}
.ReactModal__Overlay--after-open {
  top: 8em;
  background-color: rgba(0, 0, 0, 0) !important;
}
.loading-overlay,
.refresh-loading-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: $refreshLoadingOverlayZIndex;
}
.loading-overlay {
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  left: 0;
  background-color: #72727271;
  &.content-fallback {
    position: absolute;
    width: 100%;
    left: unset;
  }
}
.refresh-loading-overlay {
  top: 6.5rem;
  right: 2rem;
}
.header-shadow {
  top: $headerHeight;
}

.filter-container {
  display: flex;
  align-items: center;
  .filter-text {
    width: 10rem;
  }
}
.calender-link {
  cursor: pointer;
  font-family: $fontWeightRegular;
  font-size: smaller;
  padding: 0.3rem 0 0;
  width: fit-content;
}

/***** SVG DEFAULTS *****/
.plus-sign {
  .a {
    fill: none;
    stroke: #4eaad5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }
}

.minus {
  .a,
  .b {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }
  .b {
    opacity: 0;
  }
}
.large-mail-icon {
  * {
    fill: $textDefault;
  }
}
.fm-image-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .fm-btn {
    margin: 0.5rem 0;
  }
}

@media (max-width: 986.99px) {
  .ovis-modal-scroll-container {
    min-width: unset;
  }
  .ovis-modal-content-container {
    width: 100%;
  }
}
@media (max-width: 767px) {
  /*** Layout ***/
  .contain {
    .row {
      .col {
        flex: auto;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .welcome {
    top: 0rem;
  }
  /*** Text ***/
}

.highcharts-root {
  font-family: $font-family !important;
}
