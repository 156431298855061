.ovis-modal {
  .ovis-modal {
    left: unset;
    &.small {
      height: 20rem;
      width: 30rem;
    }
  }
}
.confirm-text {
  display: flex;
  justify-content: center;
  * {
    margin: 0 0.5rem;
  }
  &:last-child {
    margin: 0;
  }
}
.modal-sub-title {
  color: $baseBlue;
  margin: 1rem 0;
  font-weight: 200;
  font-size: 2.22rem;
}

.modal-title {
  color: $baseBlue;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 2.5rem;
  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -25px;
    left: 0px;
    background-color: rgb(78, 170, 213);
    width: 75px;
    height: 1px;
  }
}

.modal-browser-alert {
  margin-top: 3rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  text-align: center;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.video-modal {
  .ovis-modal {
    background: $baseBlue;
    .close-btn {
      top: 0.75rem;
      right: 0.75rem;
    }
  }
  .video {
    border: none;
    width: 100%;
    height: 600px;
  }
}

@media screen and (max-width: 767px) {
  .ovis-modal-scroll-container {
    padding: $headerMobileHeight 0;
  }
  .modal-sub-title {
    font-size: 1.6rem;
  }
  .ovis-modal-scroll-container::-webkit-scrollbar-track {
    margin: $headerMobileHeight 0;
  }
}
