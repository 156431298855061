@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.search {
  &.--closed {
    .search_form {
      .ovis-panel-item {
        border-bottom: none;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  &.--open {
    .search_results {
      display: block;
    }
  }
  .ovis-panel-item:nth-child(1) {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
  }
  &_active-filters.flex-container {
    flex-wrap: wrap;
    padding-top: $spacingDefault;
    gap: 0.5% !important;
    margin-bottom: 0;
    .active-filter {
      margin: 0 $spacingSmall $spacingSmall 0;
      border-radius: $borderRadiusRg;
      background: $ovisPrimaryBack;
      color: $ovisPrimary;
      width: fit-content;
      padding: $spacingXSmall $spacingDefault;
      &:hover {
        button {
          opacity: 1;
        }
      }
      p {
        font-size: 12px;
      }
      button {
        opacity: 0;
        transition: opacity $transTimingShort ease;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 1px;
        top: 1px;
        padding: 1px;
        z-index: 2;
      }
    }
  }
  &_clear-btn {
    color: white;
    right: 0;
    margin-top: 1rem;
    background-color: $secondaryBlue;
  }
  &_clear-initial-id-button {
    &:hover {
      cursor: pointer;
    }
    & > svg {
      fill: $alertRed;
    }
  }
  &_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    svg {
      width: 22px;
      top: 4px;
      margin-right: 0.5rem;
    }
    circle,
    line {
      fill: none;
      stroke: #09264c;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
    &:hover {
      .expand-icon {
        &:before,
        &:after {
          background-color: $secondaryBlue;
        }
      }
    }
    .expand-icon {
      &:before,
      &:after {
        background-color: $baseBlue;
        opacity: 1;
      }
    }
  }
  &_wrapper {
    display: flex;
  }
  &_filters {
    width: 20%;
    min-width: 225px;
    button.ovis-btn {
      background: white;
      border-radius: $borderRadiusMd;
      padding: $spacingDefault $spacingMedium;
      width: 100%;
      height: auto;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      svg {
        width: auto;
      }
    }
    .menu_submenu_title,
    .label-text {
      font-size: $fontSM;
      top: 0 !important;
    }
    .menu_submenu {
      &:nth-child(1) {
        .menu_submenu_title {
          padding-top: 0;
        }
      }
      &:nth-last-child(1) {
        border-bottom: none;
        .menu_submenu_title {
          padding-bottom: 12px;
        }
      }
    }
    .menu_submenu {
      &_title {
        padding: 1rem 0 12px;
        font-family: $fontWeightRegular;
        > div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          svg {
            width: 20px;
          }
        }
      }
      border-bottom: 1px solid $defaultBorderGray;
    }
    .ovis-dropdown-body {
      width: 100%;
      padding: 1rem 1rem 0;
      box-shadow: 0px 2px 8px rgba(60, 79, 100, 0.25);
      border-radius: 6px;
    }
    .ovis-checkbox-group,
    .ovis-radio-group {
      flex-direction: column;
      .ovis-checkbox,
      .ovis-radio {
        width: 100%;
        margin: 0;
        padding: $spacingSmall 0;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-left: 0rem;
        &:nth-last-child(1) {
          padding-bottom: 12px;
        }
        .checkbox-span,
        .radio-span {
          margin-right: 2px;
        }
      }
      label {
        font-size: $fontSM;
        font-family: $fontWeightLight;
      }
    }
  }
  &_text {
    width: 80%;
    margin-right: $spacingSmall;
    .ovis-input::placeholder {
      font-size: 16px;
    }
  }

  @media screen and (max-width: $xs-max) {
    .search {
      &_inputs {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        & > div {
          flex-basis: 32%;
        }
      }
    }
  }

  @media screen and (max-width: $md-max) {
    .search {
      &_inputs {
        & > div {
          flex-basis: 33%;
        }
      }
    }
  }
}
