@import '../../../styles/abstracts/variables.scss';

#blogging-management {
  max-width: 90rem;
  .manage-comments {
    .filters-wrapper {
      flex-wrap: wrap;
      .component-subtitle {
        width: 100%;
      }
      .filters-panel {
        display: flex;
        padding-top: $spacingDefault;
        width: 100%;
        & > label {
          margin-right: $spacingDefault;
          flex: 1;
          &.--date_picker {
            max-width: 12rem;
          }
        }
      }
    }
  }
}
