@import '../../../styles/abstracts/variables.scss';
@import '../../../styles/abstracts/mixins.scss';

.settings-hub {
  .component-subtitle {
    margin-bottom: 0;
  }
  .ovis-bp-layout_instance {
    margin-top: $spacingMedium;
  }
  .ovis-bp-card {
    background: $appBackgroundGray;
    box-shadow: none;
    &:hover {
      .document-title {
        text-decoration: underline;
      }
    }
  }
  .flex-container {
    flex-direction: column;
  }
}
