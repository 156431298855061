@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.terms-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin-top: 1.9rem;
    font-size: 1.4rem;
  }
  p.terms-text {
    height: 20rem;
    overflow-y: scroll;
    text-align: left;
    width: 100%;
  }
  li {
    margin-bottom: 0.7em;
  }
  > div {
    width: 100%;
  }
  .ovis-checkbox {
    margin-top: 2rem;
    .label-text {
      max-width: 10rem;
    }
  }
}
.terms-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ovis-checkbox {
    .label-text {
      line-height: 1.2;
      font-size: $fontSM;
      max-width: fit-content;
    }
  }
}
