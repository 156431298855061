/*** BASE SCALE ***/
$baseSize: 16px;
$baseSizeMobile: 14px;

/*** FONTS ***/
$font-family: 'Gilroy W05 Regular', sans-serif;

/* Size */
$fontXXS: 0.512rem;
$fontXS: 0.64rem;
$fontSM: 0.875rem;
$fontMD: 1.125rem;
$fontLG: 1.563rem;
$fontXL: 2.441rem;
$fontXXL: 3.052rem;

/* Weight */
$fontWeightThin: 'Gilroy W05 Thin', sans-serif;
$fontWeightLight: 'Gilroy W05 Light', sans-serif;
$fontWeightRegular: 'Gilroy W05 Regular', sans-serif;
$fontWeightMedium: 'Gilroy W05 Medium', sans-serif;
$fontWeightBold: 'Gilroy W05 Bold', sans-serif;
$fontWeightXBold: 'Gilroy W05 Heavy', sans-serif;

/* Line Heights */
$lineHeightBase: 1.03em;
$lineHeightLarge: 1.76rem;

/*** Default colors ***/

// Base Colors
$ovisPrimary: #f89721;
$ovisPrimaryLight: #ffc856;
$ovisPrimaryDark: #bf6900;
$ovisPrimaryBack: #fff8ef;

// Greens
$successGreen: #8fdb8f;
$successGreenAccent: #007038;

// Reds
$alertRed: #d83a52;
$alertRedAccent: #b63546;

// Blues
$baseBlue: #003171;
$baseBlueSelect: darken($baseBlue, 5%);
$baseBlueAccent: #0150b5;
$baseBlueBackgroundLight: lighten($baseBlue, 20%);
$secondaryBlue: #30a8d1;
$secondaryBlueAccent: darken($secondaryBlue, 5%);
$secondaryBlueSelect: lighten($secondaryBlue, 10%);

// Grays
$textDefault: #616771;
$textLight: #33313fb2;
$textDark: #33313f;
$appBackgroundGray: #f5f7fb;
$backgroundGrayLight: #fafafa; // For controls/fixed sections of inputs/col headers etc
$navigationBackground: white;
$defaultBorderGray: #d9d9d9; // Default border for inputs etc
$componentBorderGray: #9c9eac80;
$componentBorderGrayLight: #9c9eac;
$hoverGray: rgba(0, 0, 0, 0.08);

// Blacks
$black100: rgba(0, 0, 0, 1);
$black90: rgba(0, 0, 0, 0.9);
$black80: rgba(0, 0, 0, 0.8);
$black70: rgba(0, 0, 0, 0.8);
$black60: rgba(0, 0, 0, 0.6);
$black50: rgba(0, 0, 0, 0.5);
$black30: rgba(0, 0, 0, 0.3);
$black20: rgba(0, 0, 0, 0.2);
$black10: rgba(0, 0, 0, 0.1);
$black05: rgba(0, 0, 0, 0.05);

/*** Sizes ***/
$smallHeight: 1.8rem;
$medHeight: 2.2rem;
$largeHeight: 2.7rem;

/*** Size resets for global components ***/
$sidePanelWidth: 15.1rem;
$iconBarWidth: 52.8px;
$iconBarOffSetMargin: 17.2px;
$defaultTransitionTime: 0.18s;
$headerHeight: 3.7rem;
$headerMobileHeight: 3.5rem;
$bottomNavMobileHeight: 3rem;
$sideNavIconContainerWidth: 3.5rem;

$xs-max: 766px !default; // Set xs breakpoint's max width
$sm-max: 979px !default; // Set sm breakpoint's max width
$md-max: 1199px !default; // Set md breakpoint's max width
$lg-max: 1599px !default; // Set lg breakpoint's max width
$xlg-max: 1979px !default;

/*** Borders ***/
$borderRadiusSm: 2px;
$borderRadiusRg: 4px;
$borderRadiusMd: 6px;
$borderRadiusLg: 16px;
$borderRadiusCircle: 50%;

/*** Spacing ***/
$spacingXSmall: 0.25rem;
$spacingSmall: 0.375rem;
$spacingDefault: 0.5rem;
$spacingMedium: 1rem;
$spacingLarge: 1.5rem;
$spacingXLarge: 2.5rem;
$spacingXXLarge: 3rem;
$spacingXXXLarge: 4rem;

/*** Box Shadows ***/
$boxShadowBlack: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
$boxShadowWhite: 0px 2px 4px 0px rgba(255, 255, 255, 0.3);

/*** Transitions ***/

// Timing
$transTimingShort: 70ms;
$transTimingMedium: 100ms;
$transTimingLong: 250ms;

// Cubic Beziers
$timingEnter: cubic-bezier(0, 0, 0.35, 1);
$timingExit: cubic-bezier(0.4, 0, 1, 1);
$timingStatic: cubic-bezier(0.4, 0, 0.2, 1);

/*** Border ***/
$border: 1px solid $defaultBorderGray;
$componentBorder: 1px solid $componentBorderGray;
$boxShadowBlack: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
$boxShadowWhite: 0px 2px 4px 0px rgba(255, 255, 255, 0.3);
$panelBorder: 1px solid #6f7b8a;
$backgroundShadowLight: #45597580;

$skeletonOpacity: 0.7;

/*** z-indexes ***/
$interruptWrapperZIndex: 999999;
$ovisDropdownZIndex: 100000;
$toggleNavContainerZIndex: 100000;
$widgetPanelContainerDivZIndex: 99999;
$sideNavIconContainerZIndex: 99999;
$guidedTourZIndex: 10002;
$guidedTourOverlayZIndex: 10001;
$headerZIndex: 10000;
$mapPinLocationsZIndex: 10000;
$menuContainerZIndex: 9999;
$ovisDropdownToggleZIndex: 9999;
$bottomElementContainerZIndex: 1001;
$sideNavIconZIndex: 1000;
$ovisModalCloseButtonZIndex: 200;
$sideNavContainerZIndex: 10000;
$oktaDropdownZIndex: 100;
$alertsShadowZIndex: 99;
$mobileheaderBarZIndex: 99;
$ovisDashboardEditorZIndex: 99;
$refreshLoadingOverlayZIndex: 99;
$ovisDashboardPanelOverlayZIndex: 98;
$ovisChartResizeButtonZIndex: 97;
$searchIconZIndex: 55;
$advisorTableIsOpenZIndex: 50;
$mapPinIsActiveZIndex: 50;
$guidedTourCreateStepContainerZIndex: 10;
$formSearchFiltersReactSelectZIndex: 10;
$burgerButtonZIndex: 10;
$exportToggleZIndex: 8;
$mobileNavContainerShadowZIndex: 9;
$sideNavPanelOpenZIndex: 3;
$componentOverlayZIndex: 2;
$sideNavPanelTwoZIndex: 2;
$tourStepBarZIndex: 2;
$fileInputIconZIndex: 1;
$linkInputIconZIndex: 1;
$guidedTourEditOverlayZIndex: 1;
$sideNavPanelOneZIndex: 1;
$menuSubMenuZIndex: 1;
$alertsContainerZIndex: 0;

$uploadInputZIndex: -1;
$uploadInputZIndex: -1;
$inputFileZIndex: -1;
$ovisDropdownContainerZIndex: initial;

/*** Z Indexes ***/
/***
Base: 
Side Panel: 9999
***/

/*** FONTS ***/

/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/

/* Font Sizes */
/*** Base Sizes ***/
$xl-font: 3.05rem;
$lrg-font: 1.96rem;
$md-font: 1.44rem;
$default-font: 1rem;
$sm-font: 0.95rem;
$xs-font: 0.78rem;
$xssub-font: 0.475rem;

// Grid
$grid-columns: 12 !default; // Set number of columns in the grid

$xs-max: 766px !default; // Set xs breakpoint's max width
$sm-max: 979px !default; // Set sm breakpoint's max width
$md-max: 1199px !default; // Set md breakpoint's max width
$lg-max: 1599px !default; // Set lg breakpoint's max width
$xlg-max: 1979px !default;

$gutter: 0rem !default; // Set gutter size

$content-well-max-width: 'none' !default; // Set the max-width of the content well

$sm-start: ($xs-max + 1px); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1px); // Generate md breakpoint's min width
$lg-start: ($md-max + 1px); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1px); // Generate xl breakpoint's min width

// Breakpoints
// Create breakpoint range statements to be used in media queries
$breakpoint-xs-only: 'only screen and (max-width: #{$xs-max})'; // 0 -> xs-max range
$breakpoint-sm-up: 'only screen and (min-width: #{$sm-start})'; // sm-start -> up range
$breakpoint-sm-only: 'only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})'; // sm-start -> sm-max range
$breakpoint-md-up: 'only screen and (min-width: #{$md-start})'; // md-start -> up range
$breakpoint-md-only: 'only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})'; // md-start -> md-max range
$breakpoint-lg-up: 'only screen and (min-width: #{$lg-start})'; // lg-start -> up range
$breakpoint-lg-only: 'only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})'; // lg-start -> lg-max range
$breakpoint-xl-up: 'only screen and (min-width: #{$xl-start})'; // xl-start -> up range

$breakpoints-all: (
  $breakpoint-xs-only,
  $breakpoint-sm-up,
  $breakpoint-sm-only,
  $breakpoint-md-up,
  $breakpoint-md-only,
  $breakpoint-lg-up,
  $breakpoint-lg-only,
  $breakpoint-xl-up
);
$breakpoint-ups: (
  $breakpoint-sm-up,
  $breakpoint-md-up,
  $breakpoint-lg-up,
  $breakpoint-xl-up
);
$breakpoint-all-prefixes: (
  'xs',
  'xs-only',
  'sm',
  'sm-only',
  'md',
  'md-only',
  'lg',
  'lg-only',
  'xl'
);
$breakpoint-up-prefixes: ('xs', 'sm', 'md', 'lg', 'xl');
