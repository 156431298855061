@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

#user-thumbnail {
  border-radius: $borderRadiusMd;
  background: white;
  padding: $spacingMedium $spacingDefault;
  min-height: 152px;
  p {
    margin-bottom: 0;
  }
  .user-thumbnail {
    &_title {
      font-size: $fontSM;
    }
    &_name {
      padding: 0 !important;
      height: auto !important;
      white-space: normal;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.user-hub {
  .ovis-bp-layout_instance {
    padding-top: $spacingLarge;
  }
  .flex-container {
    gap: 1%;
    .ovis-input_instance {
      flex: 2;
    }
    .ovis-bp-select {
      flex: 1;
    }
  }
  .ovis-btn.export-btn {
    height: 100%;
    background-color: $baseBlue;
    &:hover {
      background-color: $baseBlueAccent;
    }
    .ovis-loader-container {
      width: 90px;
      .ovis-loader {
        border-color: $componentBorderGray;
        border-top-color: white;
      }
    }
  }
  @include laptop {
    &.--side-nav-open {
      .ovis-bp-layout.--grid.--col-3 {
        gap: 1%;
        .col {
          width: 49%;
        }
      }
    }
  }
  @include largeTablet {
    .flex-container {
      .ovis-input_instance {
        input {
          height: 1.85rem;
        }
      }
    }
    &.--side-nav-open {
      .flex-container {
        flex-flow: wrap;
        .ovis-input_instance {
          flex: auto;
          margin-bottom: $spacingMedium;
        }
      }
      .ovis-bp-layout.--grid.--col-3 {
        gap: 0%;
        .col {
          width: 100%;
        }
      }
    }
  }
  @include tablet {
    .flex-container {
      flex-flow: wrap;
      .ovis-input_instance {
        flex: auto;
        margin-bottom: $spacingDefault;
      }
    }
  }
}
