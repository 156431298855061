@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.searchHighlight,
.search-highlight {
  font-family: $fontWeightXBold;
}

.custom-html-wrapper {
  ul,
  ol {
    padding-left: $spacingLarge;
  }
}

.global-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 5.5rem);
  width: 100%;
  max-width: 80rem;
  .user-thumbnail {
    margin-bottom: $spacingDefault;
  }
  .search_template {
    margin-bottom: $spacingLarge;
  }
  .custom-html-wrapper {
    ul,
    ol {
      padding-left: $spacingLarge;
    }
  }
  .dm-search {
    &_link {
      margin-bottom: $spacingMedium;
      .link-render {
        font-family: $fontWeightMedium;
        color: $textDark;
        font-size: $fontMD;
      }
    }
  }
  .component-title {
    margin-bottom: $spacingMedium;
  }
  .search-help {
    &_title {
      margin-bottom: $spacingMedium;
      font-family: $fontWeightMedium;
      font-size: $fontMD;
      color: $textDark;
      cursor: pointer;
    }
  }
  &_result {
    margin-bottom: $spacingMedium;
    padding-bottom: $spacingMedium;
    border-bottom: 1px solid $textDark;
    &:nth-last-child(1) {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &_sort-by {
    display: flex;
  }
  &_sort-by-select {
    width: 250px;
  }
  &_result-inner {
    display: flex;
    margin-right: 1rem;
  }
  &_result-count,
  &_results {
    width: 100%;
  }
  &_result-count {
    display: flex;
    margin-bottom: $spacingDefault;
  }
  &_no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15rem;
  }
  &_loading {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20rem;
  }
}

.search-template {
  display: flex;
  width: 100%;
  min-height: 15rem;
  height: fit-content;
  padding: 1rem 0;
  border-bottom: 1px solid black;
  &.--user {
    .search-template_image {
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
    }
  }
  &_title-container {
    display: flex;
    justify-content: space-between;
    .ovis-tooltip-tip {
      color: white;
    }
  }
  &_title {
    display: inline-block;
    font-family: $fontWeightRegular;
    margin-bottom: 1rem;
    line-height: 1.8rem;
    text-align: left;
    &:hover {
      color: $secondaryBlue;
    }
  }
  &_info {
    font-size: 0.95rem;
    margin-bottom: 0.4rem;
    a {
      color: $secondaryBlue;
    }
  }
  &_date {
    flex: 1;
    margin-right: 0.5rem;
    max-width: 150px;
  }
  &.--no-image {
    .search-template_date {
      flex: 1;
    }
  }
  &_body {
    flex: 5;
    margin-right: 2rem;
  }

  &.--expand {
    .search-template_description {
      & > p:nth-child(n + 3) {
        display: none;
      }
      &.--description-open {
        & > p:nth-child(n + 3) {
          display: inline-block;
        }
      }
    }
    .search-template_button {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
      & > button {
        color: white;
        background-color: $secondaryBlue;
      }
    }
  }
}

@media screen and (max-width: $xs-max) {
  .global-search_container {
    padding: 1.5rem;
    top: 3.5rem;
  }
  .global-search {
    &_pagination {
      margin-top: 1rem;
    }
    &_result-count {
      flex-direction: column;
    }
    &_sort-by {
      margin-top: 1rem;
    }
  }
  .search-template {
    flex-direction: column;
    padding: 1.5rem 0;
    &.--user {
      .search-template_image {
        width: 10rem;
        height: 10rem;
      }
    }
    &_date,
    &_module-name {
      font-size: 0.7rem;
    }
    &_date {
      flex: 0;
      font-family: $fontWeightRegular;
    }
    &_title {
      display: block;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
    &_image {
      float: right;
      .cover-image_image {
        width: 5rem;
        height: auto;
        margin: 0 1.5rem 0.3rem 0.3rem;
      }
    }
    &_hidden-image {
      visibility: hidden;
    }
  }
}
