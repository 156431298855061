.bg-white {
  background-color: white;
}

.bg {
  background-color: $baseBlue;
}

.pseudo-bg {
  &::before,
  &::after {
    background-color: $baseBlue !important;
  }
}

.--panel {
  background: white;
  border-radius: $borderRadiusMd;
  padding: $spacingLarge;
}
