.profile-photo {
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.--small {
    width: 35px;
    height: 35px;
  }
  &.--medium {
    width: 50px;
    height: 50px;
  }
  &.--large {
    width: 75px;
    height: 75px;
  }
}
