@import '../abstracts/variables';

.okta-dropdown-container {
  .okta-dropdown {
    min-height: 42rem;
    transition: height 0.25;
  }
  .ovis-dropdown-body {
    width: 50rem;
    max-width: 75vw;
    max-height: 42rem;
    .okta-dashboard-iframe {
      max-height: 78vh;
    }
  }
  .ovis-panel {
    .widget-content {
      height: 100%;
    }
  }
}
.okta-dashboard-iframe {
  width: 100%;
  height: 42rem;
  border: none;
}

.okta-controls {
  .ovis-checkbox-group {
    .ovis-checkbox {
      width: 100%;
    }
  }
}
