.auth-required-link {
  color: $textDefault;
  cursor: pointer;
  text-transform: none;
  text-align: left;
  transition: color 0.01s ease;
  .right-arrow {
    margin-left: 0.5rem;
  }
  svg {
    width: 2rem;
  }
  .cls-1 {
    fill: $secondaryBlue;
  }
  &:not(&.--no-content) {
    &:hover {
      text-decoration: underline;
      svg .cls-1 {
        fill: $secondaryBlue;
      }
    }
  }
}
