.document-group {
  max-width: 630px;
  .document-group_title {
    margin-bottom: 1rem;
  }
  &_body {
    max-height: 275px;
    overflow-x: scroll;
    padding-right: 30px;
  }
  &_title-container {
    display: flex;
    justify-content: space-between;
    .document {
      width: 3rem;
      border-bottom: none !important;
      margin-top: -3px;
    }
  }
  &_filters {
    margin: 2rem 0 2.3rem;
    .css-1d2rcc8-container,
    .css-1tuhdb7-control {
      background: transparent;
      box-shadow: none;
      border-color: $textDefault;
      height: 86%;
      > * {
        color: $textDefault
      }
    }
    path {
      fill: $textDefault
    }
    .css-26l3qy-menu {
      background: white;
    }
    .css-des4zg-control,
    .css-1tuhdb7-control {
      background: transparent;

      padding: 0.5rem;
      > div > div {
        color: $textDefault !important;
      }
    }
    .css-j26jrx-ValueContainer {
      .css-3hgqdm-Placeholder {
        color: $textDefault
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .document {
    padding: 0.5rem 0;
    display: block;
    background: url('/assets/SS/logos/pdf.svg') 100% 10px/25px no-repeat;
    border-bottom: 1px solid $defaultBorderGray;
    &:hover {
      cursor: pointer;
      .document_title {
        color: var(--accent);
      }
    }
    p {
      color: $textDefault;
      transition: color 0.3s ease;
      padding-right: 30px;
    }
    &_title {
      margin-bottom: 0em;
      line-height: 1.5;
    }
    &_desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  &_link-render {
    width: 100%;
    .document {
      width: 100%;
      text-align: left;
    }
  }
}

@media (max-width: $sm-max) {
  .document-group {
    max-width: initial;
  }
}
