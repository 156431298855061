.--alert-btn {
  background: $alertRed;
  &:hover {
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.65);
  }
}

.--default-btn {
  padding: $spacingXSmall;
  border-radius: 6.4px;
  transition: background-color 167ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  color: $textDefault;
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
}
