#help-management {
  .panel {
    justify-content: flex-start;
  }
  .auth-required-link .right-arrow {
    display: none;
  }
  .row-buttons {
    display: flex;
    .table-action-icon {
      margin: 0 5px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 14px;
        height: 15px;
      }
    }
  }
  form {
    p.label-text {
      text-transform: capitalize;
    }
  }
}
#help {
  width: 100%;
  .asset-add-btn,
  .ovis-btn.submit {
    background-color: $secondaryBlue;
    &:hover {
      background-color: lighten($secondaryBlue, 10%);
    }
  }
  .asset-button {
    svg {
      path {
        fill: $secondaryBlue;
      }
    }
  }
  .doc-link {
    .auth-required-link {
      color: white;
      &:hover {
        color: $secondaryBlue;
      }
    }
  }
  .ovis-panel-item {
    justify-content: center;
  }
  .ovis-editor-root {
    background-color: white;
    color: black;
  }
}
#help-management,
#help {
  .DraftEditor-editorContainer {
    z-index: unset;
  }
}
