@import '../../styles/abstracts/variables';

.tour-step {
  background: #ffffff;
  width: 35rem;
  position: fixed;
  border-radius: $borderRadiusRg $borderRadiusRg 8px 8px;
  box-shadow: 8px 8px 11px #00000061;
  &_time {
    position: absolute;
    top: $spacingSmall;
    &.--current {
      left: $spacingSmall;
    }
    &.--total {
      right: $spacingSmall;
    }
  }
  &_status {
    position: absolute;
    top: $spacingXSmall;
    left: $spacingSmall;
    color: $textDefault;
    font-weight: 500;
  }
  .close {
    display: flex;
    justify-content: flex-end;
    height: 2rem;
    .tour-close-btn {
      margin: 0.3rem 0.3rem 0 0;
      width: 1.1rem;
      height: 1rem;
      svg {
        width: 1rem;
      }
    }
  }

  .tour-text {
    padding: 0 $spacingDefault $spacingSmall;
    margin: 0 0 0.5rem 0;
    display: flex;
    overflow-y: scroll;
  }

  .tour-control {
    background: $secondaryBlue;
    color: #ffffff;
    padding: $spacingSmall $spacingDefault;
    height: 5rem;
    display: flex;
    justify-content: space-around;
    border-radius: 0 0 $borderRadiusRg $borderRadiusRg;
    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;

      width: 175px;
    }
    .time {
      display: flex;
      align-items: center;
    }
  }

  .bar {
    z-index: $tourStepBarZIndex;
    background: linear-gradient(to right, $secondaryBlue 50%, #f1f3f5 0);
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__progress {
      flex: 1;
      margin: 0 0.25rem;
      border-radius: 5px;
      height: 0.25rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-color: $secondaryBlue;
      }
    }
  }
}
.audio-control-btn {
  background: $secondaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: $borderRadiusCircle;
  cursor: pointer;
  &:hover {
    background-color: lighten($secondaryBlue, 5%);
  }
  &.previous {
    transform: rotate(180deg);
  }
  svg {
    color: #ffffff;
    width: 1rem;
    left: 1px;
    path {
      fill: white;
    }
  }
  .play-icon {
    left: 1px;
  }
  .pause-icon {
    left: 2px;
  }
}
