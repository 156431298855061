.module-search-container {
  min-height: 20rem;
}
.module-search-loading-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
}
.module-search-loader {
  width: 5rem;
  display: flex;
  justify-content: center;
}
