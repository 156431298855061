/* Basic Reset for all elements */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

.App {
  min-height: 100vh;
  background: $appBackgroundGray;
}

/*** Font/Text Resets ***/
br:nth-last-child(1) {
  display: none;
}

svg {
  width: 100%;
  height: auto;
}
