@import '../abstracts/variables';

.ovis-dashboard {
  .reporting-date {
    p {
      color: #fff;
    }
  }

  p {
    margin-bottom: 0;
  }

  .ovis-panel {
    width: auto;
  }

  .ovis-dashboard-editor {
    top: 30%;
    z-index: $ovisDashboardEditorZIndex;
    &-button {
      .ovis-icon {
        stroke: #fff;
        fill: none;
        transition: stroke .2s ease;
      }
      &:hover .ovis-icon {
        stroke: $secondaryBlueAccent;
      }
    }
    
  }

  .ovis-dashboard-layout {
    .ovis-dashboard-panel {
      .ovis-chart {
        .ovis-chart_resize-button {
          z-index: $ovisChartResizeButtonZIndex;
        }
      }
      .ovis-dashboard-panel-overlay {
        z-index: $ovisDashboardPanelOverlayZIndex;
      }
    }
  }
}

.dashboard-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 1rem 0;
  }
}
