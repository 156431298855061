    .ovis-preview-button_modal {
        .ovis-modal_scroll-container{
            height: fit-content;
            overflow: hidden;
        }
        .ovis-modal_loading-text {
            color: white;
        }
    }

