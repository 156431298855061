@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.help-center {
  &_title-wrapper {
    .page-title {
      width: fit-content;
      margin-bottom: 0;
    }
  }
  .ovis-bp-select {
    width: 250px;
    & > div > * {
      background: $appBackgroundGray;
    }
  }
  &_navigation {
    display: flex;
    button {
      font-size: $fontMD;
      font-family: $fontWeightMedium;
      color: $textDark;
      margin-right: $spacingSmall;
      &.--focused {
        background: $black05;
      }
    }
  }
  &_body {
    padding-bottom: 6rem;
  }
  &_guided-tour {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &_content-section {
    margin: 1rem 0;
    &:nth-last-child(1) {
      padding-bottom: 0;
      &::after {
        display: none;
      }
    }
    label {
      margin-right: 1.5rem;
      flex: unset;
      display: flex;
      align-items: center;
    }
    .col__md-1 {
      display: flex;
      align-items: center;
    }
  }
  &_assets-title {
    margin: 0.85rem 0;
    font-size: 1.1rem;
    font-weight: 300;
  }
  &_description {
    margin-top: 1.5rem;
  }
  .guided-tour-play-btn {
    border-radius: 0;
    margin: 0 1rem;
    height: 2.6rem;
    cursor: pointer;
    &:hover {
      svg {
        rect {
          fill: $secondaryBlue;
        }
      }
    }
    .a {
      fill: #307fc3;
    }
    .b {
      fill: #fff;
    }
  }
  &_title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &_title {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    margin-bottom: 0;
  }
  &_search-table {
    .search-result {
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-top: 1px solid $defaultBorderGray;
      padding: 0.5rem 0.15rem;
      &:hover {
        background-color: $black05;
      }
      &:nth-child(1) {
        border-top: none;
      }
      &_link {
        text-align: left;
        svg {
          width: 25px;
        }
      }
      &_name {
        color: $textDark;
        font-size: 1.1rem;
        font-family: $fontWeightRegular;
        margin-bottom: 0;
      }
      &_description {
        font-size: 0.85rem;
        margin-bottom: 0;
        color: $textDefault;
      }
    }
  }

  /*** Modal Resets ***/
  .ovis-modal {
    min-width: unset;
  }
  .ovis-modal-scroll-container {
    padding-top: 5rem;
    width: 48rem;
    overflow: auto;
  }
  .ovis-modal-scroll-container::-webkit-scrollbar-track {
    margin: 4rem 0;
  }
  .search-wrapper {
    transform: unset;
    margin-left: 1rem;
  }
  .ovis-modal-title {
    &:after {
      display: none;
    }
  }
  .ovis-modal-content-container {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    flex: 1;
    margin-top: 0.5rem;
  }
  #directory-drop {
    padding-left: 0.4rem;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .help-center {
    width: 98%;
    .ovis-modal_title-wrapper {
      height: auto;
    }
    .ovis-modal_title {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &_title-wrapper {
      flex-direction: column;
      align-items: flex-start;
      .search-wrapper {
        margin: 2.5rem 0 1.5rem 0;
        &.--open {
          position: relative;
          margin: 2.5rem 0 1.5rem 0;

          left: 0;
          top: 0;
          transform: translate(0, 0);
        }
        .search-input {
          .search-icon {
            top: 2px;
          }
        }
      }
    }
    /*** Blueprints overwrites ***/
    .ovis-modal {
      padding: 0.7rem;
      height: calc(100vh - calc(#{$headerMobileHeight} + 1rem));
    }
    .ovis-modal-scroll-container {
      padding: calc(#{$headerMobileHeight} + 0.5rem) 0 0.5rem;
      overflow-y: auto;
    }
    .ovis-modal-title {
      margin-bottom: 0;
    }
    .ovis-modal-title-wrapper {
      height: initial;
      margin-top: 1rem;
      align-items: flex-start;
    }
    .modal-sub-title {
      margin: 0;
    }
  }
  .guided-tour-help-modal {
    display: none !important;
  }
}
