@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.header {
  &_logo {
    height: 100%;
    padding: $spacingXSmall 0;
    a {
      display: block;
      height: 100%;
      img {
        max-height: 100%;
      }
    }
  }
}

.nav-user {
  height: 100%;
  background-color: inherit !important;
  display: flex;
  padding: 0 1rem 0 0.75rem;
  justify-content: flex-end;
  .ovis-btn {
    padding: 0;
    height: 100%;
    background: transparent !important;
  }
  .ovis-dropdown-container {
    width: initial;
    display: inline-flex;
    align-items: center;
  }
  .profile-photo {
    margin-right: $spacingDefault;
  }
  &-dropdown {
    top: 60px !important;
    .ovis-dropdown-body {
      width: 10rem;
      padding: 0;
    }
    ul {
      margin-bottom: 0;
    }
    li.menu_item {
      transition: all 0.2s;
      display: flex;
      justify-content: center;
      color: black;
      padding-left: 0 !important;
      &:hover {
        background-color: $hoverGray;
      }
      a,
      button,
      div {
        color: inherit;
        transition: unset;
        display: block;
        text-align: center;
        height: 100%;
        padding: 0.5rem;
        width: 100%;
      }
    }
  }
}

.header {
  height: $headerHeight;
  max-height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  border-bottom: $border;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $headerZIndex;
  padding-left: 1rem;
  .header_icon {
    height: 100%;
    &.--search-override {
      &:hover {
        background: transparent !important;
      }
    }
  }
  &_right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_right-icon {
    .ovis-btn {
      height: 100%;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    > p {
      font-size: 10px;
      line-height: 1;
      text-align: center;
      top: 5px;
    }
    svg {
      width: 30px;
    }
  }
  .global-search_dropdown {
    width: 350px;
    margin-right: $spacingDefault;
    .ovis-dropdown-body {
      padding: $spacingXSmall;
      width: 500px;
    }
  }
  .global-search_input {
    .ovis-input_container {
      background: $appBackgroundGray;
    }
  }
  .global-search {
    &_preview-result {
      padding: $spacingSmall;
      border-radius: $borderRadiusMd;
      &:hover {
        background: $hoverGray;
      }
      p {
        font-size: 1rem;
        color: $textDark;
        span {
          font-size: $fontSM;
          color: $textDefault;
        }
      }
    }
  }
  &_alert,
  &_directory {
    max-width: 48px;
  }
  &_fav-icon {
    top: 4px;
    &.--open,
    &:hover {
      svg {
        * {
          stroke: $secondaryBlue;
          fill: $secondaryBlue;
        }
      }
    }
    svg {
      #Group_55 {
        display: none;
      }
      * {
        stroke: $baseBlue;
        transition: fill 1s ease;
        stroke-width: 2px;
      }
    }
  }
  &_static-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;
    height: 100%;
    width: max-content;
    padding: 0 $spacingSmall;
    & > p {
      margin-top: 1rem;
      color: black;
    }
    & > span {
      max-width: 1.78rem;
      max-height: 1.78rem;
      margin-right: 0.4rem;
      margin-left: 0.3rem;
      & > svg {
        width: 100%;
      }
    }
    &:hover {
      svg {
        path {
          fill: $secondaryBlue;
        }
      }
    }
  }
  .fav-icon {
    .header_icon-title {
      top: 4px;
    }
  }
  &_fav-icon,
  .alert-icon {
    svg {
      transform: scale(0.9);
      width: 26px;
    }
  }
  .alert-icon {
    display: flex;
    top: 1px;
    svg {
      width: 30px;
    }
  }
  &_directory-icon {
    align-items: center;
  }

  .okta-dropdown-container {
    .okta-dropdown {
      width: calc(100vw - 5.8rem);
      height: calc(100vh - 99px);
      position: fixed;
      top: 99px;
      left: 4.1rem;
      transform: translate(0);
      &.--side-nav-open {
        width: calc(100vw - 5.8rem - #{$sidePanelWidth});
        left: $sidePanelWidth + 4.1rem;
      }
      .ovis-dropdown-body {
        width: 100%;
        max-width: initial;
        max-height: initial;
        &::before {
          display: none;
        }
      }
      .okta-dashboard-iframe {
        height: calc(92vh - 120px);
        max-height: initial;
      }
    }
  }
  .alert-toggle-icon {
    .alert-icon {
      height: 42px;
      .circle {
        border-radius: 100%;
        background: $alertRed;
        height: 22px;
        width: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: absolute;
        right: -8px;
        top: -1px;
        z-index: 2;
        p {
          color: white;
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }
  .ovis-dropdown-body {
    box-shadow: $boxShadowBlack;
  }
  &_icons-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex: 3;
    .ovis-dropdown-toggle {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .ovis-dropdown-body {
      padding: 0;
    }
  }
  &_icon {
    min-width: 3rem;
    height: $headerHeight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  &_icon-hover-title,
  &_icon-title {
    user-select: none;
    font-size: 0.59rem;
    font-family: $fontWeightRegular;
    color: grey;
    line-height: 0.7rem;
    top: 0.1rem;
  }
  &_icon-hover-title {
    position: absolute;
    bottom: 0;
    top: unset;
    text-align: center;
  }
  .icon-dropdown-container {
    height: $headerHeight;
  }
  .logo-container {
    flex: 3.5;
    display: flex;
    align-items: center;
  }
  .logo-nav {
    height: 2.9rem;
    margin: 0 5em 0 3em;
    max-height: 250px;
    max-width: 250px;
  }
  .nav-orgs {
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
    caret-color: transparent;
    .active-org-img {
      // margin-left: 2em !important;
      max-height: $headerHeight;
    }
    .active-org-no-menu {
      margin: 1em;
    }
  }
  &_menu {
    ul {
      margin: 0;
    }
    .menu_item {
      padding-left: 0 !important;
    }
    a {
      display: flex;
      align-items: center;
      color: black;
    }
  }
  .search-wrapper {
    min-width: 0;
    z-index: 99999;
    transition: all 0.3s ease;
    padding: 0;
    left: 0;
    transform: translate(0);
    &.--closed {
      max-width: 3rem;
    }
    .close-btn {
      height: 1.9rem;
      width: 1.9rem;
      background: $baseBlue;
      display: none;
      svg {
        fill: white;
      }
      &:hover {
        background: $baseBlueAccent;
      }
    }
    .search-input {
      flex: none;
      .search-icon {
        top: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        svg {
          width: 30px;
          height: 100%;
          right: 0;
        }
      }
    }
    &.--open {
      transform: translate(-41%);
      .close-btn {
        display: flex;
      }
      .search-input {
        .search-icon svg {
          right: -3px;
        }
        input {
          padding: 0 $spacingSmall 0;
        }
      }
    }
  }

  &_search-icon {
    p {
      margin-top: 5px;
    }
  }
  .nav-user-dropdown {
    width: 10rem;
    .ovis-dropdown-body {
      width: 10rem;
      right: 0rem;
    }
    .profile-menu {
      .profile-menu-item {
        display: flex;
        align-items: center;
        & > a {
          padding: 0.5rem;
          color: black;
        }
        & > button {
          padding: 0.5rem;
          color: black;
        }
      }
    }
  }
}
.solutions-icon {
  .icon-dropdown-icon {
    width: 29px;
    margin-bottom: 0.3rem;
  }
}
.icon-dropdown-container {
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 0.7rem;
  padding: 0 $spacingSmall;
  .icon-dropdown-toggle {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 25px;
    user-select: none;
  }
  .expand-icon {
    right: 5px;
    top: 55%;
    &::before,
    &::after {
      opacity: 0.9;
    }
  }
}
.icon-dropdown-icon {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
}

.logout-container {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
  transition: background-color 0.18s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.018);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.036);
  }
  .logout-btn {
    font-size: 0.8em;
    margin: 0.5em;
    color: $textDefault;
    font-weight: 600;
    cursor: pointer;
  }
}

@media screen and (max-width: 1150px) {
  .icon-dropdown-toggle.--not {
    h4 {
      visibility: hidden;
      width: 0;
    }
  }
}

@media screen and (max-width: 1065px) {
  .header .header-icons-container .header-icon {
    min-width: 5rem;
  }
}
@media screen and (max-width: $sm-max) {
  .header {
    .nav-user {
      padding: 0 $spacingXSmall;
    }
    &_right-wrapper {
      padding-right: 1rem;
    }
    .okta-dropdown-container .okta-dropdown .okta-dashboard-iframe {
      height: calc(92vh - 120px);
      max-height: initial;
    }
    &_static-icon {
      &.back-to > p {
        visibility: hidden;
        width: 0;
      }
    }
  }
  @media screen and (min-width: calc($xs-max + 1px)) and (max-width: $sm-max) {
    .icon-dropdown-container {
      .icon-dropdown-toggle {
        &.--is {
          width: fit-content;

          h4 {
            visibility: hidden;
            width: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 767px) and (max-width: 815px) {
  .header {
    &_static-icon {
      margin-right: 0rem;
    }
  }
}

@media screen and (max-width: $xs-max) {
  .header {
    width: 100%;
    right: 0;
    left: unset;
    height: 100%;
    position: relative;
    border: none;
    padding-left: 0;
    &_search {
      max-width: 30px;
    }
    &_right-wrapper {
      padding-right: 0rem;
      max-width: 316px;
    }
    .search-wrapper.--closed .search-icon {
      top: 3px;
      right: 1px;
    }
    &_icons-wrapper {
      flex: auto;
    }
    .ovis-btn {
      padding: 0;
    }
    &_icons-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: $bottomNavMobileHeight;
      background-color: white;
      border-top: 1px solid $defaultBorderGray;
      z-index: 20000000;
      justify-content: center;
      .icon-dropdown-container {
        margin-right: 0.5rem;
        height: $bottomNavMobileHeight;
      }
      .icon-dropdown-toggle {
        font-size: 0.6rem;
        padding-right: 0;
      }
      .icon-dropdown-icon {
        display: flex;
        align-items: center;
        width: 17px;
        svg {
          width: 17px;
        }
      }
      .expand-icon {
        right: -12px;
        top: 60%;
        width: 8px;
        &::before,
        &::after {
          opacity: 0.9;
          height: 0.5px;
          width: 5px;
        }
      }
      .suite-dropdown {
        left: 7.3rem;
        .ovis-dropdown-body::before {
          left: 30%;
        }
      }
      .okta-dropdown-container {
        .ovis-dropdown {
          top: unset;
          transform: unset;
          position: fixed;
          min-height: unset;
          max-height: unset;
          bottom: 2.2rem;
          left: 0.25rem;
          width: calc(100vw - 0.5rem);
          max-width: initial;
          transform: translate(0px, 0px);
          .ovis-dropdown-body {
            width: 100%;
            max-width: unset;
            max-height: calc(100vh - 4rem);
          }
          .ovis-dropdown-body::before {
            left: 85%;
          }
        }
      }
    }
    &_icon {
      min-width: 1.5rem;
      height: 100%;
      .ovis-btn {
        height: 100%;
      }
    }
    &_right-icon {
      width: 2.5rem;
      margin-right: 0.5rem;
    }
    &_alerts-icon {
      margin-right: 0;
    }
    &_fav-icon {
      height: 1.5rem;
      top: 2px;
      svg {
        height: 20px;
      }
    }
    &_directory-icon {
      margin-right: 0;
      height: 1.5rem;
      svg {
        height: 20px;
        top: 1px;
      }
    }
    .alert-toggle-icon {
      height: 1.5rem;
      bottom: unset;
      .alert-icon {
        top: -2px;
        .circle {
          height: 18px;
          width: 18px;
          p {
            font-size: 10px;
          }
        }
      }
      svg {
        height: 25px;
      }
    }
    .icon-dropdown-toggle {
      h4 {
        visibility: visible;
        width: initial;
      }
    }
    .search-wrapper {
      margin: 0;
      &.--closed {
        padding: 0.8rem;
        background-color: transparent;
        bottom: 0.1rem;
        transform: scale(0.9);
        .search-icon {
          svg {
            circle,
            line {
              stroke: black;
            }
          }
        }
      }
      &.--open {
        position: fixed;
        left: 0;
        top: 0.2rem;
        transform: translate(0, 0);
        width: 100vw;
        border: 1px solid $defaultBorderGray;
      }
    }
  }
  .nav-user-dropdown {
    .ovis-dropdown-body::before {
      left: 7.5rem;
    }
  }
  .marketplace-dropdown {
    &.--mobile {
      transform: translate(-32%, calc(-100% - 15px));
    }
  }
}
