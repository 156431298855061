@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

$gtShadowColor: rgba(128, 128, 128, 0.7);
$gtBoxShadow: 0 0 0 99999px $gtShadowColor;

.guided-tour {
  z-index: $guidedTourZIndex;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem;
}

.guided-tour-overlay,
.guided-tour-edit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .circle {
    display: fixed;
    position: fixed;
    box-shadow: $gtBoxShadow;
    border-radius: 0.5rem;
  }
  .circle-arrow {
    position: fixed;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%) rotate(45deg);
    content: '';
    display: block;
    background: white;
    top: 0;
  }
}
.guided-tour-overlay {
  z-index: $guidedTourOverlayZIndex;
  &.loading {
    background-color: $gtShadowColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.guided-tour-edit-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $guidedTourEditOverlayZIndex;
  pointer-events: none;
  top: $headerHeight;
  left: $iconBarWidth;
}

.guided-tour-selectable {
  width: auto;
  height: 100%;
  &.--no-click {
    pointer-events: none !important;
  }
  .guided-tour-clickable {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
}

.guided-tour-management {
  .actions {
    button {
      width: 1rem;
      height: 1rem;
      margin: 0.25rem;
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
}

.guided-tour-create-step-container {
  pointer-events: none;
  .selected-element {
    margin-left: $spacingDefault;
    color: $textDark;
  }
  button,
  .ovis-bp-select {
    margin-bottom: $spacingMedium;
  }
  .ovis-bp-select {
    & > div > div {
      background-color: $appBackgroundGray;
    }
  }
  label {
    margin-bottom: $spacingSmall;
    display: block;
  }
  .guided-tour-create-step-window {
    pointer-events: all;

    .modal-title {
      margin-top: 0.5rem;
    }
    .close-btn {
      position: absolute;
      top: 1rem;
      right: 2rem;
    }
  }
  .scroll-container {
    overflow-y: visible;
    width: 99%;
    padding: 0 0.5%;
    margin-bottom: 0.6rem;
    margin-top: 2rem;
  }
  button.submit {
    position: absolute;
    bottom: 1.5rem;
  }
  .tour-step {
    position: absolute;
    bottom: 35%;
    left: 30%;
  }
  .form-sub-title {
    margin: 1.5rem 0 0.5rem;
    font-weight: 300;
    font-size: 1.2rem;
    &:first-child {
      margin: 0 0 0.5rem;
    }
  }
  .ovis-text-area {
    background: $appBackgroundGray;
  }
  textarea {
    margin: 0 0 1rem;
    background: $appBackgroundGray;
    font-size: 0.85rem;
  }
}
