@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.login-page {
  height: 100vh;
  @include flex-center;
  background: $backgroundGrayLight;
  &.system-message {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .app-logo {
      margin-bottom: $spacingLarge;
      max-width: 350px;
    }
    .logo-nav {
      margin-bottom: 1rem;
    }
    h3 {
      text-align: center;
      margin-bottom: 1rem;
      max-width: 65rem;
    }
    button {
      color: #40a9ff;
    }
  }

  .wrapper {
    max-width: 485px;
    min-height: 511px;
    width: 100%;
    .okta-widget-container {
      min-height: 25rem;
    }

    .auth-org-logo {
      height: 4.5rem;
      max-width: 100%;
    }
    #okta-sign-in {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      width: 100%;
      label {
        color: $textLight;
        font-size: $fontSM;
        font-family: $fontWeightMedium;
      }
      * {
        font-family: $font-family !important;
      }
      .mfa-verify-passcode {
        .auth-passcode {
          width: 100%;
        }
        .sms-request-button {
          margin: 0.5rem 0 1rem 0;
          border: none;
          background: $secondaryBlue;
        }
        .o-form-fieldset-container {
          display: flex;
          flex-direction: column;
        }
        .o-form-fieldset {
          display: inline-block;
        }
      }
      .okta-sign-in-header {
        border-bottom: none;
        padding: 0.83rem 0 2.77rem;
      }
      .o-form-content {
        padding-bottom: 0;
      }
      .auth-org-logo {
        min-height: initial;
        max-width: initial;
        max-height: initial;
        height: 150px;
      }
      .focused-input {
        box-shadow: none;
      }
      .o-form-fieldset {
        margin-bottom: 1.11rem;
      }
      .icon.icon-16.error-16-small {
        left: 2px;
        top: 8px;
      }
      .okta-form-input-error {
        padding-left: 25px;
      }
      h2 {
        display: none;
      }
      .o-form-button-bar {
        padding: 0 0 $spacingDefault;
      }
      .email-button {
        margin-bottom: $spacingDefault;
      }
      .button-primary {
        @include flex-center;
        border-radius: $borderRadiusRg;
        cursor: pointer;
        padding: $spacingSmall $spacingDefault;
        height: $spacingXLarge;
        letter-spacing: normal;
        font-family: $fontWeightMedium;
        box-shadow: 0 2px #0000000b;
        font-size: inherit;
        line-height: 1.5715;
        white-space: nowrap;
        text-align: center;
        transition: background-color $transTimingLong $timingStatic,
          border-color $transTimingLong $timingStatic,
          color $transTimingLong $timingStatic;
        touch-action: manipulation;
        background: $baseBlue;
        height: $largeHeight;
        font-size: 1rem;
        text-shadow: none;
        box-shadow: none;
        border: none;
        &:hover {
          background-color: $baseBlueAccent;
        }
        &.--disabled {
          background: $baseBlue;
        }
      }
      .okta-form-input-field {
        border-radius: 0;
        box-shadow: none;
        height: auto;
        padding: 0rem $spacingMedium;
        border-radius: 2px;
        border: 1px solid $defaultBorderGray;
        height: $largeHeight;
        @include flex-center-column;
      }
      input[type='text'],
      input[type='password'] {
        border-radius: 0;
        color: $textDefault;
        font-family: $font-family;
        font-size: 1rem;
        outline: none;
        height: 29px;
        padding: 0;
      }
      input[type='text']::placeholder,
      input[type='password']::placeholder {
        color: $textDefault;
        display: block;
        font-size: 0.83rem;
        font-family: $font-family;
        font-weight: 600;
        left: 1.16rem;
        letter-spacing: 0.2em;
        position: absolute;
        top: 0rem;
        transition: 0.1s opacity ease;
        text-transform: uppercase;
      }
      .auth-footer {
        font-size: $fontSM;
      }
      .auth-content {
        padding: 0;
        .auth-content-inner {
          .sms-request-button {
            background-color: $secondaryBlue;
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .login-page {
    .landing {
      height: auto;
      flex-direction: column;
      > div {
        min-height: 650px;
        height: auto;
        padding: 7.44rem 1.5rem 5.11rem;
        width: 100%;
        .wrapper {
          min-height: initial;
        }
      }
      .loginCallout {
        .auth-header {
          padding-top: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .login-page {
    .landing {
      > div {
        min-height: initial;
        height: auto;
      }
      .loginContainer {
        height: 100vh;
      }
    }
    .contact-form {
      .row {
        flex-direction: column;
        margin-bottom: 0;
        .col {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
