.department-contacts {
  &.content {
    max-width: 1171px;
    margin: auto;
  }

  &_container {
    .ovis-pagination {
      display: none !important;
    }

    
  }
}

@media screen and (max-width: $xs-max) {
  .department-contacts {
    &_container {
      .ovis-table-cell {
        & > a {
          font-size: .67rem;
  
        }
      }
    }
  }
}