@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.alerts {
  p {
    margin-bottom: 0;
  }
  .side-panel {
    padding-left: 0;
    padding-right: 0;
  }
  &-panel {
    .side-panel_header {
      padding-left: 0;
    }
  }
  .alert {
    border: $border;
    border-top: 2px solid $baseBlue;
    border-radius: 5px;
    padding: $spacingSmall $spacingSmall $spacingXLarge;
    margin-bottom: 0.5rem;
    display: flex;
    &_title {
      margin-bottom: 0.25rem;
      line-height: 1.4;
      button {
        text-align: left;
      }
      span {
        margin-right: 0.25rem;
        top: -1px;
      }
      button,
      span,
      p,
      div {
        color: $textDark;
        font-family: $fontWeightMedium;
      }
      p {
        transition: opacity 0.25s ease;
        &:hover {
          opacity: 0.65;
        }
      }
    }
    &_content {
      width: 100%;
    }
    &_has-seen {
      color: $successGreen !important;
      font-size: 0.85em;
      font-family: $fontWeightRegular;
      position: absolute;
      right: $spacingSmall;
    }
    &_favicon {
      padding-right: $spacingSmall;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 2px;
      &-image {
        margin-bottom: $spacingXSmall;
        height: 35px;
        width: 35px;
      }
      svg {
        width: 35px;
        height: 35px;
        path {
          &.a {
            fill: #09264c;
          }
          &.b {
            fill: #307fc3;
          }
        }
      }
    }
    &_reply {
      position: absolute;
      right: $spacingDefault;
      bottom: $spacingSmall;
      svg {
        width: 15px;
        margin-right: $spacingXSmall;
      }
      button {
        display: flex;
        align-items: center;
        padding: $spacingXSmall;
        justify-content: center;
        border-radius: 6.4px;
        transition: background-color 167ms cubic-bezier(0.4, 0, 0.2, 1);
        color: $textDefault;
        &:disabled {
          opacity: 1;
          svg {
            * {
              fill: $secondaryBlue;
            }
          }
          p {
            color: $secondaryBlue;
          }
        }
        &:hover,
        &:disabled {
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
  &_container {
    z-index: $alertsContainerZIndex;
    padding: $spacingSmall 0rem;
    @media screen and (max-width: 767px) {
      .widget-content {
        height: 22.5rem;
      }
    }
    .widget-content {
      height: 100%;
    }
  }
  &_panel-container {
    max-height: 37rem;
    height: unset;
    &.--empty {
      .widget-content {
        height: 100%;
      }
    }
  }
  &_empty {
    text-transform: none;
    color: white;
    height: 10.34rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .widget-panel-container {
    & > * {
      z-index: $widgetPanelContainerDivZIndex;
      @media (max-width: 847px) {
        width: 100vw;
        top: $headerMobileHeight;
        margin-left: 0;
      }
    }
  }
}

.alert-toggle-icon {
  display: flex;
  justify-content: center;
  bottom: 0.1rem;
  .alert-toggle_count {
    font-size: $fontXS;
    background: $alertRed;
    border-radius: $borderRadiusCircle;
    height: 17px;
    width: 17px;
    @include flex-center;
    position: absolute;
    top: 3px;
    right: 3px;
    color: white;
    z-index: 3;
  }
  &.--hover {
    margin-left: 0.1rem;
    margin-top: 0.3rem;
  }
}
