@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.document {
  &_title {
    font-size: $fontMD;
    font-family: $fontWeightRegular;
  }
  &_date {
    color: $textLight;
    font-size: $fontSM;
  }
}

.document-details {
  p {
    color: $textDefault;
  }
  .document {
    &_date {
      margin-bottom: 0.25rem;
      color: $black90;
    }
    &_org-name {
      color: $black60;
      font-size: $sm-font;
    }
    &-title {
      margin-bottom: 0;
    }
    &_desc {
      font-size: $sm-font;
    }
  }
}

.dm-template.--feed {
  max-width: 720px;
  z-index: 1;
  .document {
    text-align: left;
    background: white;
    padding: $spacingMedium $spacingLarge;
    margin-bottom: $spacingLarge;
    border-radius: $borderRadiusLg;
    .cover-image_image {
      border-radius: 11px/15px;
    }
    &_title {
      .auth-required-link {
        font-size: $spacingLarge;
        color: $textDark;
        line-height: $lineHeightLarge;
        font-family: $fontWeightMedium;
      }
    }
    &_date {
      font-size: $fontSM;
      font-family: $fontWeightRegular;
      color: $textLight;
      margin-bottom: 0;
    }
    &_desc {
      color: $textDark;
      font-size: $fontSM;
      line-height: $lineHeightBase;
      font-family: $fontWeightRegular;
      padding: $spacingLarge 0 $spacingDefault;
    }
    &_preview_tile {
      margin-right: 0.5rem;
      display: flex;
      align-items: flex-start;
      & > button {
        margin-top: 1px;
      }
    }
  }
}

.dm-template.--list {
  .document {
    background: white;
    padding: $spacingMedium $spacingLarge;
    border-radius: $borderRadiusLg;
    margin-bottom: $spacingMedium;
    border: $componentBorder;
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
    &_date {
      margin-left: $spacingMedium;
      top: 3px;
      &:hover {
        text-decoration: none !important;
      }
    }
    &_title {
      font-family: $fontWeightMedium;
    }
    &_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 80%;
      & > div {
        display: flex;
      }
    }
    .dm-blogging_action {
      width: fit-content;
      flex: 0;
      margin-right: 0;
      button {
        width: auto;
        margin-right: 0;
      }
    }
  }
}
.dm-template.--tile {
  .document {
    text-align: left;
    @include borderRadiusBottom;
    &_preview_tile {
      margin-right: 0.5rem;
      display: flex;
      align-items: flex-start;
      & > button {
        margin-top: 1px;
      }
    }
    &_content {
      height: 100%;
      padding-bottom: 3.5rem;
      width: 100%;
    }
  }
  &.--no-images {
    .document {
      .cover-image_image {
        display: none;
      }
    }
  }
  &.--no-description {
    .document {
      .document_description {
        display: none;
      }
    }
  }
  &.--has-org-name {
    .document {
      .document_info-container {
        .document_org-name {
          display: flex;
        }
      }
    }
  }
  &.--tile-3-col {
    .grid {
      display: flex;
      flex-flow: wrap;
    }
    .document {
      width: 32.5%;
      margin-right: calc(2.5% / 2);
    }
    .document:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  &.--tile-2-col {
    .grid {
      display: flex;
      flex-flow: wrap;
    }
    .document {
      width: 49%;
    }
    .document:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
  &.--tile-1-col {
    .grid {
      display: flex;
      flex-flow: wrap;
    }
    .document {
      width: 100%;
      margin: 0 0 1rem 0;
      display: flex;
      flex-direction: row;
      height: auto;
      p {
        text-align: left;
      }
      .doc_info-container {
        height: 375px;
        width: 100%;
      }
      .cover-image {
        &_image {
          width: 666px;
          height: 375px;
          background-color: white;
        }
      }
    }
    .document:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  &.carousel-template {
    .document {
      margin: 0;
      .document_desc {
        font-size: 1rem;
      }
    }
    .carousel {
      min-height: 375px;
    }
    @media (max-width: $sm-max) {
      .carousel {
        position: relative;
        min-height: unset !important;
        overflow-y: visible;
        .carousel-wrapper {
          position: relative;
        }
        &-slide {
          align-items: initial;
          .document {
            .document_info-container,
            .document_preview-image {
              background-position: center;
              background-size: cover;
              min-height: 375px;
              height: 100%;
            }
            height: 100%;
          }
        }
      }
    }
  }
  .document {
    background: white;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-bottom: 2%;
    .cover-image_image {
      height: auto;
      width: 100%;
    }
    .document_info-container {
      padding: 1.11rem 1.22rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      .document_date {
        font-size: 13px;
        letter-spacing: 1.8px;
        margin-bottom: 0.3rem;
        min-height: 0.8rem;
      }
      .document_title {
        font-size: 1.16rem;
        font-family: $fontWeightRegular;
        line-height: 1.19em;
        margin-bottom: 0.5rem;
      }
      .document_author {
        font-size: 0.85rem;
      }
      .document_desc {
        font-weight: 300;
      }
      .doc_org-name {
        margin-top: -0.25rem;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
        display: none;
      }
    }
    .document_link {
      display: flex;
      color: $textDefault;
      font-size: 13px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      line-height: 2.4em;
      margin: auto 1rem 0 0;
      align-items: center;
      .auth-required-link {
        letter-spacing: 0.15em;
        display: flex;
        text-transform: uppercase;
        svg {
          width: 1.1rem;
          margin-left: 0.45rem;
          top: 0px;
          transform: translate(0, -2%);
          transition: all 0.2s ease;
        }
        .cls-1 {
          fill: $secondaryBlue;
        }
        &:hover {
          svg {
            transform: translate(1px);
          }
          svg .cls-1 {
            fill: $secondaryBlue;
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .dm-template {
    &.--side-nav-open {
      &.--tile {
        &.--tile-1-col {
          .document {
            .cover-image_image {
              display: none;
            }
            .doc_info-container {
              height: 300px;
            }
          }
        }
        &.carousel-template {
          .carousel {
            min-height: 300px;
          }
        }
      }
    }
  }
}

@media (max-width: 1259.99px) {
  .dm-template {
    &.--tile {
      &.--tile-3-col {
        .document {
          width: 49%;
        }
        .document:nth-child(3n + 3) {
          margin-right: 2%;
        }
        .document:nth-child(2n + 2) {
          margin-right: 0%;
        }
      }
    }
  }
}

@media (max-width: $md-max) {
  .dm-template {
    &.--tile {
      &.--tile-1-col {
        .document {
          .cover-image_image {
            display: none;
          }
          .doc_info-container {
            height: 300px;
          }
        }
      }
      &.carousel-template {
        .carousel {
          min-height: 300px;
        }
      }
    }
  }
}

@media (max-width: 979.99px) {
  .dm-template {
    &.--tile,
    &.--list,
    &.--table {
      padding: 1.25rem 1rem;
    }
    &.--side-nav-open {
      &.--tile {
        &.--tile-3-col {
          .document {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
    &.--tile {
      &.--tile-2-col {
        .document {
          width: 100%;
          margin-right: 0;
        }
      }
      &.--tile-1-col {
        .document {
          width: 100%;
        }
        .document {
          flex-direction: column !important;
          .doc_info-container {
            width: 100%;
            display: block;
          }
          .doc_info-container {
            display: flex;
            height: 300px;
          }
          .doc_link {
            svg {
              width: 1.3rem;
            }
          }
        }
      }
    }
    &.--list {
      .wrapper {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 766.99px) {
  .dm-template {
    &.--tile {
      &.--tile-3-col {
        .document {
          width: 100%;
        }
        .document {
          margin-bottom: 5%;
          &:nth-child(3n + 3),
          & {
            margin-right: 0%;
          }

          .doc_link {
            .auth-required-link {
              font-size: 10px;
              margin-right: 0.5rem;
            }
            svg {
              width: 1.3rem;
              margin-left: 0.3rem;
            }
          }
        }
      }
    }
  }
}
