@import '../../styles/abstracts/variables.scss';
@import '../../styles/abstracts/mixins.scss';

.page-nav {
  height: 100vh;
  width: 200px;
  position: fixed;
  left: $iconBarWidth;
  background: white;
  padding: $spacingLarge $spacingDefault;
  border-right: 1px solid #d9d9d9;
  z-index: 10;
  .page-nav-link {
    display: block;
    text-transform: capitalize;
    padding: $spacingSmall $spacingDefault;
    color: $textLight;
    font-size: 0.9rem;
    text-align: left;
    border-radius: 2px;
    transition: color $transTimingLong $timingStatic;
    &:hover {
      color: $baseBlueSelect;
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.05);
      color: $baseBlueSelect;
    }
  }
}

@media screen and (max-width: 1100px) {
  .page-nav-link {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 767px) {
  .page-nav {
    flex-direction: column;
    align-items: center;
  }
  .page-nav-link {
    letter-spacing: 2px;
    padding: 1.8em 1em;
    max-width: 85%;
  }
}
