@import '../../../styles/abstracts/variables';

#terms-management {
  max-width: 70rem;
  .flex-container {
    justify-content: space-between;
  }
  .ovis-btn {
    .ovis-loader-container {
      width: 160px;
      .ovis-loader {
        border-color: $componentBorderGray;
        border-top-color: white;
      }
    }
  }
  .ovis-editor {
    max-width: 100%;
    width: 100%;
    .ProseMirror {
      max-height: 35rem;
      overflow: scroll;
    }
  }
}
